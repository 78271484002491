import { FormField, Input } from '@amzn/awsui-components-react';
import { ReactElement, useEffect, useState } from 'react';
import { FieldProps } from '../FieldProps';
import {
  ListOfferingReviewsCommand,
  ListOfferingsCommand,
} from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import { callApi } from '../../../client';
import { useAuthState } from '../../../authentication';

export const RipServiceIdField = (
  props: FieldProps<string, true>
): ReactElement => {
  const { token } = useAuthState();
  const [valid, setValid] = useState(false);
  const [ids, setIds] = useState<string[]>();
  useEffect(() => {
    if (props.disabled || !token) return;
    void Promise.all([
      callApi(new ListOfferingsCommand({}), token),
      callApi(new ListOfferingReviewsCommand({}), token),
    ])
      .then((res) => {
        const ids: string[] = [];
        for (const o of [...res[0].offerings, ...res[1].offeringReviews])
          if ('ripServiceId' in o && o.ripServiceId)
            ids.push(o.ripServiceId.toLocaleLowerCase());
        setIds(ids);
      })
      .catch((e) => console.log('Error listing offerings:', e));
  }, [props.disabled, token]);
  useEffect(
    () =>
      setValid(
        Boolean(
          (/^.{1,255}$/.test(props.value.trim()) ||
            !(props.required || props.value.length)) &&
            ids &&
            (props.value === props.old ||
              !ids.includes(props.value.toLocaleLowerCase()))
        )
      ),
    [props.value, props.required, ids]
  );
  useEffect(() => props.onValidate?.(valid), [valid]);
  return (
    <FormField label='RIP service ID'>
      {typeof props.old === 'string' &&
        props.old !== props.value &&
        props.disabled && <Input disabled={true} value={props.old} />}
      <Input
        disabled={props.disabled}
        value={props.value}
        invalid={!props.disabled && !valid}
        onChange={({ detail: { value } }) => {
          props.onChange?.(value);
        }}
      />
    </FormField>
  );
};
