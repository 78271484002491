import {
  EntityInformation,
  RegionProfiling,
} from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import { Empty } from './f2/Empty';
import styles from './Entable.module.sass';
import { ReactElement } from 'react';

export const Entable = ({
  entities,
}: {
  entities: EntityInformation[];
}): ReactElement => (
  <table className={styles.Entable}>
    <thead>
      <tr>
        {/* <th>Locale</th> */}
        <th>ID</th>
        <th>All</th>
        <th>All except BJS</th>
        <th>BJS</th>
        <th>Universal</th>
      </tr>
    </thead>
    <tbody>
      {entities.map(({ service }) => (
        <tr>
          {/* <td>{ent.locale}</td> */}
          <td>{service?.entityId}</td>
          {!(service?.value && Object.keys(service.value).length) ? (
            <td>
              <Empty />
            </td>
          ) : (
            Object.values(RegionProfiling).map((p) => (
              <td>
                {p in service.value ? (
                  service.value[p]?.value.map((s) => s.value).join(' ')
                ) : (
                  <Empty />
                )}
              </td>
            ))
          )}
        </tr>
      ))}
    </tbody>
  </table>
);
