import { InputWrapperProps } from '../../../interfaces';
import { Button, Grid, Input } from '@amzn/awsui-components-react';
import { ReactElement } from 'react';

const InputWrapper = ({
  inputValue,
  removable,
  stringArray,
  updateStringArray,
}: InputWrapperProps): ReactElement => (
  <Grid disableGutters gridDefinition={[{ colspan: 9 }, { colspan: 3 }]}>
    <Input value={inputValue} disabled />
    {removable && updateStringArray && (
      <Button
        iconName='status-negative'
        variant='link'
        onClick={() =>
          updateStringArray(stringArray.filter((item) => item !== inputValue))
        }
      >
        Remove
      </Button>
    )}
    {!removable && ''}
  </Grid>
);

export default InputWrapper;
