import styles from './LocSubscribersField.module.sass';
import { Button, FormField, SpaceBetween } from '@amzn/awsui-components-react';
import { AliasSuggester } from '../../../components/AliasSuggester';
import { FieldProps } from '../FieldProps';
import { ReactElement } from 'react';

export const LocSubscribersField = (
  props: FieldProps<string[], true>
): ReactElement => (
  <FormField label='Notify stakeholders' className={styles.LocSubscribersField}>
    <SpaceBetween direction='vertical' size='s'>
      {props.value.map((v, i) => (
        <SpaceBetween size='s' direction='horizontal'>
          <AliasSuggester
            aliasToAdd={v}
            setAliasToAdd={(a) =>
              props.onChange?.([
                ...props.value.slice(0, i),
                a,
                ...props.value.slice(i + 1),
              ])
            }
          />
          <Button
            iconName='remove'
            className={'remove'}
            variant='icon'
            onClick={() =>
              props.onChange?.([
                ...props.value.slice(0, i),
                ...props.value.slice(i + 1),
              ])
            }
          />
        </SpaceBetween>
      ))}
      <div className={styles.addRow}>
        <Button
          onClick={() => props.onChange?.([...props.value, ''])}
          iconName='add-plus'
          variant='inline-icon'
        >
          Add
        </Button>
      </div>
    </SpaceBetween>
  </FormField>
);
