import { OfferingStatus } from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import {
  StatusIndicator,
  StatusIndicatorProps,
} from '@amzn/awsui-components-react';
import { ReactElement } from 'react';

const statusMap: Record<OfferingStatus, StatusIndicatorProps> = {
  DELETED: {
    type: 'error',
    children: 'Deleted',
  },
  PUBLISHED: {
    type: 'success',
    children: 'Published',
  },
} as const;

export const OfferingStatusIndicator = ({
  status,
}: {
  status: OfferingStatus;
}): ReactElement => <StatusIndicator {...statusMap[status]} />;
