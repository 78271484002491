import Box from '@amzn/awsui-components-react/polaris/box';
import { ReactElement, ReactNode } from 'react';

interface KeyValueComponent {
  label: string | JSX.Element;
  children: ReactNode;
}

const ValueWithLabel = ({
  label,
  children,
}: KeyValueComponent): ReactElement => (
  <>
    <Box margin={{ bottom: 'xxxs' }}>{<b>{label}</b>}</Box>
    <div>{children}</div>
  </>
);

export default ValueWithLabel;
