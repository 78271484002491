import {
  Header,
  Table,
  BoxProps,
  StatusIndicator,
} from '@amzn/awsui-components-react';
import { PACKAGE_STRINGS } from '../../../constants/UIStrings';
import { formatName } from '../utils';
import { ReactElement } from 'react';

interface Owners {
  writers: string[];
  editors?: string[];
  writerMangers: string[];
  pointOfContact?: string;
  isInheritedPointOfContact: boolean;
  isThisServicePage: boolean;
}

interface OwnershipData {
  role: string;
  names: string[] | undefined;
  override: BoxProps.Display;
}

export default function OwnershipTable({
  writers,
  editors,
  writerMangers,
  pointOfContact,
  isInheritedPointOfContact,
  isThisServicePage,
}: Owners): ReactElement {
  const owners: OwnershipData[] = [];
  owners.push({ role: 'Writers', names: writers, override: 'none' });
  owners.push({ role: 'Editors', names: editors, override: 'none' });
  owners.push({
    role: "Writer's manager",
    names: writerMangers,
    override: 'none',
  });

  if (pointOfContact) {
    const overrideIndicator: BoxProps.Display = !isInheritedPointOfContact
      ? 'block'
      : 'none';
    owners.push({
      role: 'Point of contact',
      names: [pointOfContact],
      override: overrideIndicator,
    });
  }

  const indicatorText = isThisServicePage
    ? 'Package-level override exists'
    : 'Package-level override';

  return (
    <Table
      columnDefinitions={[
        {
          id: 'role',
          header: 'Role',
          cell: (item: OwnershipData) => item.role || '-',
        },
        {
          id: 'names',
          header: 'Names',
          cell: (item: OwnershipData) => formatName(item.names) || '-',
        },
        {
          id: 'overrideIndicator',
          header: 'status',
          cell: (item: OwnershipData) => {
            return item.override === 'block' ? (
              <StatusIndicator type='info'>{indicatorText}</StatusIndicator>
            ) : (
              <>-</>
            );
          },
        },
      ]}
      items={owners}
      loadingText='Loading owners'
      header={
        <Header description={PACKAGE_STRINGS['Ownership']}>Ownership</Header>
      }
    />
  );
}
