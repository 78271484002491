import { ReactElement, useEffect } from 'react';
import { FetchedServiceDetailData } from '../../interfaces';
import {
  Box,
  Button,
  ColumnLayout,
  Container,
  Header,
  SpaceBetween,
  Spinner,
  StatusIndicator,
} from '@amzn/awsui-components-react/polaris';
import PackageListTable from './ui/PackageListTable';
import AdditionalDetailsTable from './ui/AdditionalDetailsTable';
import ValueWithLabel from '../ui/ValueWithLabel';
import ValueWithDescription from '../ui/ValueWithDescription';
import {
  getPackageVisbility,
  getKeyValuePairsAsList,
  getRestrictedStatus,
} from './utils';
import {
  ErrorRefreshPage,
  ErrorServiceNotFoundInRetrievedData,
} from '../ui/ErrorLoadingPage';
import { useNavDispatch } from '../../components/navigation/context';
import { useLocation } from 'react-router';
import OwnershipTable from './ui/OwnershipTable';
import styles from './ServiceDetail.module.scss';
import { useAuthState } from '../../authentication';
import { SERVICE_STRINGS } from '../../constants/UIStrings';
import { LeadershipChainList } from '../../components/LeadershipChainList';

const ServiceDetail = ({
  serviceData,
  serviceDataNotFound,
  loading,
  packageDataList,
}: FetchedServiceDetailData): ReactElement => {
  const dispatch = useNavDispatch();
  const location = useLocation();
  const { packageInfoPermissionsGroup } = useAuthState();

  useEffect(() => {
    dispatch({
      type: 'ADD_BREADCRUMB_OVERRIDE',
      overrides: {
        [location.pathname]: serviceData?.serviceDisplayName ?? '',
      },
    });
  }, [serviceData?.serviceDisplayName]);

  // Still retrieving data
  if (loading) {
    return <Spinner size='large' />;
  }

  // Data was not retrieved
  if (!serviceData) {
    return <ErrorRefreshPage />;
  }

  // Data retrieved but requested service not found
  if (serviceDataNotFound) {
    return <ErrorServiceNotFoundInRetrievedData />;
  }

  const pocOverrideInicatorFlag =
    packageDataList.filter(
      (item) =>
        !item.inheritedPointOfContact && item.pointOfContact !== undefined
    ).length > 0;

  if (serviceData && !serviceDataNotFound) {
    // Requested service included in retrieved data
    return (
      <Box margin={{ top: 'l', bottom: 'xxl' }}>
        <SpaceBetween size='l'>
          <Header
            variant='h1'
            actions={
              packageInfoPermissionsGroup &&
              packageInfoPermissionsGroup == 'info-editor' && (
                <Button href={`#${location.pathname}/edit`}>Edit</Button>
              )
            }
          >
            {serviceData.serviceDisplayName} {}
            {pocOverrideInicatorFlag ? (
              <Box>
                <StatusIndicator type='info'>
                  Package-level override exists
                </StatusIndicator>
              </Box>
            ) : (
              <></>
            )}
          </Header>

          <Container
            header={
              <Header
                variant='h2'
                description={SERVICE_STRINGS['Naming conventions']}
              >
                Naming conventions
              </Header>
            }
          >
            <ColumnLayout columns={2} variant='text-grid'>
              <ValueWithLabel label='Service display name'>
                {serviceData.serviceDisplayName ?? serviceData.serviceId}
              </ValueWithLabel>
              <ValueWithLabel label='RIP service ID'>
                {serviceData.serviceId}
              </ValueWithLabel>
            </ColumnLayout>
          </Container>

          <Container header={<Header variant='h2'>Status</Header>}>
            <ColumnLayout columns={2} variant='text-grid'>
              <ValueWithDescription
                label='Visibility'
                description={SERVICE_STRINGS['Visibility']}
              >
                {getPackageVisbility(serviceData.visibility)}
              </ValueWithDescription>
              <ValueWithDescription
                label='Restricted status'
                description={SERVICE_STRINGS['Restricted status']}
              >
                {getRestrictedStatus(serviceData.restrictedStatus)}
              </ValueWithDescription>
              <ValueWithDescription
                label='Funding status'
                description={SERVICE_STRINGS['Funding status']}
              >
                {serviceData.isFunded ? (
                  <StatusIndicator type={'success'}>Funded</StatusIndicator>
                ) : (
                  <StatusIndicator type={'stopped'}>Not funded</StatusIndicator>
                )}
              </ValueWithDescription>
              <ValueWithDescription
                label='Region status'
                description={SERVICE_STRINGS['Region status']}
              >
                <Box>
                  {getKeyValuePairsAsList(
                    serviceData.regionStatus,
                    styles.regionList
                  )}
                </Box>
              </ValueWithDescription>
            </ColumnLayout>
          </Container>
          <Container header={<Header variant='h2'>Leadership chain</Header>}>
            <LeadershipChainList
              chain={serviceData.serviceContact?.leadershipChain}
              badges={true}
            />
          </Container>

          <OwnershipTable
            writers={serviceData.writers ?? []}
            writerMangers={serviceData.writerManager ?? []}
            editors={serviceData.editors}
            pointOfContact={serviceData.pointOfContact}
            isInheritedPointOfContact={!pocOverrideInicatorFlag}
            isThisServicePage={true}
          />
          <PackageListTable
            serviceId={serviceData.serviceId}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            packageDataList={packageDataList}
          />

          <AdditionalDetailsTable
            pathSlugs={serviceData.pathslug ?? []}
            altPathslug={serviceData.altPathslug}
            feedbackCTI={serviceData.feedbackCTI ?? []}
            feedbackSIM={serviceData.feedbackSIM ?? []}
          />
        </SpaceBetween>
      </Box>
    );
  }

  // If we get to this point, there was some kind of error
  return <ErrorRefreshPage />;
};

export default ServiceDetail;
