import { Link, StatusIndicator, Box } from '@amzn/awsui-components-react';
import { getEnvironment } from '../../authentication/config';
import { AwsService } from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import { ReactElement, ReactNode } from 'react';

enum StatusIndicatorType {
  error = 'error',
  success = 'success',
  stopped = 'stopped',
  pending = 'pending',
}

export const getServiceLink = (
  serviceDisplayName: string,
  serviceId: string
): ReactElement => (
  <Link href={`#/services/${encodeURIComponent(serviceId)}`} key={serviceId}>
    {serviceDisplayName && serviceDisplayName !== 'None'
      ? serviceDisplayName
      : serviceId}
  </Link>
);

export const getPackageLink = (
  serviceId: string,
  packageName: string
): ReactElement => (
  <Link
    href={`#/services/${encodeURIComponent(serviceId)}/${encodeURIComponent(
      packageName
    )}`}
    key={packageName}
  >
    {packageName}
  </Link>
);

export const getListOfPackageLinks = (
  serviceId: string,
  packages: string[]
): ReactElement[] | string[] =>
  !packages || packages.length === 0
    ? ['-']
    : packages.map((packageName, i) => (
        <div key={packageName}>
          {getPackageLink(serviceId, packageName)}
          {i < packages.length - 1 ? ', ' : ''}
        </div>
      ));

export function getListOfPathSlugs(
  pathSlugs: string[]
): string | string[] | ReactElement[] {
  if (!pathSlugs || pathSlugs.length === 0) {
    return ['-'];
  }

  if (pathSlugs.length === 1) {
    return pathSlugs[0];
  }

  const max = pathSlugs.length - 1;

  return pathSlugs.map((pathslug, i) => (
    <div key={`${pathslug}${i}`}>
      {pathslug}
      {i < max ? ', ' : ''}
    </div>
  ));
}

export function getRegionStatus(statusObject: Record<string, string>): string {
  if (Object.entries(statusObject).length < 1) {
    return '-';
  }
  const sortedRegions = Object.keys(statusObject).sort();
  return sortedRegions.reduce(
    (string, region, i) =>
      `${string}${region}: ${statusObject[region]}${
        i < sortedRegions.length - 1 ? '; ' : ''
      }`,
    ''
  );
}

export function getKeyValuePairsAsList(
  keyValuePairs?: Record<string, string>,
  className?: string
): ReactElement {
  if (!keyValuePairs || Object.entries(keyValuePairs).length < 1) {
    return <>-</>;
  }
  const sortedKeys = Object.keys(keyValuePairs).sort();
  const listItems = sortedKeys.map((sortedKey) => {
    return (
      <li key={sortedKey}>
        {sortedKey}:&nbsp;{keyValuePairs[sortedKey]}
      </li>
    );
  });

  return <ul className={className || ''}>{listItems}</ul>;
}

// TODO: Remove the any type below when calling the the real APIs
export function getServiceDataMap(
  serviceData: AwsService[]
): Map<string, AwsService> {
  const serviceDataMap = new Map<string, AwsService>();
  serviceData.forEach((service) => {
    serviceDataMap.set(service.serviceId, service);
  });
  return serviceDataMap;
}

export const getRestrictedStatus = (status: string): ReactNode =>
  status === 'Null' || !status ? (
    '-'
  ) : (
    <StatusIndicator
      type={
        StatusIndicatorType[
          status.toLowerCase() !== 'restricted' ? 'success' : 'pending'
        ]
      }
    >
      {status}
    </StatusIndicator>
  );

export const getPackageVisbility = (visibility: string): ReactElement => (
  <StatusIndicator
    type={
      StatusIndicatorType[
        visibility.toLowerCase() == 'external' ? 'success' : 'stopped'
      ]
    }
  >
    {visibility}
  </StatusIndicator>
);

export const getFeedbackSIMFolder = (feedbackSIM: string[]): ReactNode =>
  !feedbackSIM || feedbackSIM.length === 0 || feedbackSIM[0] === '' ? (
    <>-</>
  ) : (
    feedbackSIM.map((SIM) => <div id={SIM}>{SIM}</div>)
  );

// NEEDS UI VERIFICATION
export const getFeedbackFolderLink = (feedbackCTI: string[]): ReactElement[] =>
  !feedbackCTI || feedbackCTI.length === 0
    ? [<>-</>]
    : feedbackCTI.map((CTI) => {
        //Expecting back-end to return CTI in format ${category}/${type}/${item}
        const ctiSplit = CTI.split('/');
        const ctiTrimmed = ctiSplit.map((element) => element.trim());
        const ctiHref = `https://t.corp.amazon.com/issues?q=extensions.tt.status%3A%28Assigned%20OR%20Researching%20OR%20%22Work%20In%20Progress%22%20OR%20Pending%29%20AND%20currentSeverity%3A%281%20OR%202%20OR%203%20OR%204%20OR%205%20OR%202.5%20OR%20N%29%20AND%20extensions.tt.category%3A${
          ctiTrimmed[0]
        }%20AND%20extensions.tt.type%3A${
          ctiTrimmed[1]
        }%20AND%20extensions.tt.item%3A%22${encodeURIComponent(
          ctiTrimmed[2]
        )}%22`;
        return (
          <div id={CTI}>
            <Link
              external
              href={ctiHref}
              externalIconAriaLabel={'Open external link'}
            >
              {CTI}
            </Link>
          </div>
        );
      });

const addCommaToArrayOfStrings = (list: string[]) => {
  const newlist = list.map((str) => {
    if (str.includes('@')) {
      return str.split('@')[0] || str.split('@')[1];
    } else {
      return str;
    }
  });
  return newlist.map((str, i) => (i === list.length - 1 ? str : str + ', '));
};

export function getServiceOwners({
  serviceId,
  pointOfContact,
  writers,
  editors,
  writerManager,
  className,
}: {
  serviceId: string;
  pointOfContact: string;
  writers: string[];
  editors: string[];
  writerManager: string[];
  className?: string;
}): ReactElement {
  const owners = [];
  if (pointOfContact.length > 0) {
    owners.push(
      <li key={`${serviceId}poc`}>
        Point&nbsp;of&nbsp;contact:&nbsp;
        {addCommaToArrayOfStrings([pointOfContact])}
      </li>
    );
  }

  if (writers.length > 0) {
    owners.push(
      <li key={`${serviceId}writers`}>
        Writers:&nbsp;{truncateArray(addCommaToArrayOfStrings(writers), 3)}
      </li>
    );
  }

  if (editors.length > 0) {
    owners.push(
      <li key={`${serviceId}editors`}>
        Editors:&nbsp;{truncateArray(addCommaToArrayOfStrings(editors), 3)}
      </li>
    );
  }

  if (writerManager.length > 0) {
    owners.push(
      <li key={`${serviceId}writerManager`}>
        {writers.length > 1 ? "Writers'" : "Writer's"}&nbsp;manager:&nbsp;
        {truncateArray(addCommaToArrayOfStrings(writerManager), 3)}
      </li>
    );
  }

  if (owners.length === 0) {
    return <div>-</div>;
  } else return <ul className={className || ''}>{owners}</ul>;
}

export function truncateArray(
  list: string[] | JSX.Element[],
  truncateLength = 5
): ReactNode[] {
  const truncatedList = list.slice(0, truncateLength);
  const hasMoreItems = list.length > truncateLength;

  return hasMoreItems
    ? [
        ...truncatedList,
        <span>...and {list.length - truncateLength} more</span>,
      ]
    : truncatedList;
}

export function formatName(input: string[] | undefined): JSX.Element[] {
  if (!input?.length) return [<Box>-</Box>];

  return input.map((str) => {
    const [name = '', alias = ''] = str.split('@');

    if (str.includes('@amazon.com')) {
      return <Box>{str}</Box>;
    } else if (name === '' || alias === '') {
      return <Box>{`${name || alias}`}</Box>;
    }

    return (
      <Box>
        {`${name} `}
        {alias && (
          <Link
            href={`https://phonetool.amazon.com/users/${alias}`}
          >{`(@${alias})`}</Link>
        )}
      </Box>
    );
  });
}

// TODO: add stage and API endpoints to context
export const getApiInvokeUrl = (): string =>
  `https://user-api.${getEnvironment()}.virtualsmiley.docs.aws.a2z.com`;

const { hostname } = window.location;
export const isProd = (): boolean =>
  window.location.hostname.startsWith('prod.');

export const isDevDesk = (): boolean =>
  hostname.startsWith('dev-dsk-') || hostname.endsWith('.aka.corp.amazon.com');

export const isLocalHost = (): boolean => hostname === 'localhost';

export const isDevOrLocal = (): boolean => isLocalHost() || isDevDesk();
