import { LocalizationGuidance } from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import { FormField, Select } from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { ReactElement } from 'react';
import { FieldProps } from '../FieldProps';
const empty: OptionDefinition = {
  value: undefined,
  label: 'Select...',
};
const labels: Record<LocalizationGuidance, string> = {
  'OK to Translate': 'Fully localizable',
  'Partial Translation OK': 'Partial Do Not Translate',
  'Keep In English': 'Full Do Not Translate',
};
const options = Object.fromEntries(
  Object.values(LocalizationGuidance).map((v) => [
    v,
    {
      label: labels[v],
      value: v,
    },
  ])
);

export const LocalizationGuidanceField = (
  props: FieldProps<LocalizationGuidance | undefined, false>
): ReactElement => (
  <FormField label='Localization guidance'>
    {props.old && props.old !== props.value && (
      <Select
        disabled={true}
        options={[empty, ...Object.values(options)]}
        selectedOption={
          typeof props.old === 'string' ? options[props.old] : empty
        }
        placeholder='Empty'
      />
    )}
    <Select
      disabled={props.disabled}
      options={[empty, ...Object.values(options)]}
      selectedOption={
        typeof props.value === 'string' ? options[props.value] : empty
      }
      placeholder='Select...'
      onChange={(e) => {
        const { value } = e.detail.selectedOption;
        if (value && value !== '.')
          props.onChange?.(value as LocalizationGuidance);
      }}
    />
  </FormField>
);
