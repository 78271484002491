import { Input, InputProps } from '@amzn/awsui-components-react';
import { ReactElement, useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './Validatable.module.sass';

type Props = InputProps &
  React.RefAttributes<InputProps.Ref> & { regex?: RegExp | false };

export const Validatable = ({
  value,
  className,
  regex,
  onChange,
  ariaRequired,
  ...etc
}: Props): ReactElement => {
  const [val, setVal] = useState(value);
  const [wrong, setWrong] = useState(false);
  useEffect(() => {
    setVal(value);
  }, [value]);
  return (
    <Input
      value={val}
      className={classNames(className, { [styles.wrong]: wrong })}
      onChange={(d) => {
        setVal(d.detail.value);
        setWrong(
          Boolean(
            (regex && !regex.test(d.detail.value)) ||
              (ariaRequired && !d.detail.value.length)
          )
        );
        onChange?.(d);
      }}
      {...etc}
    />
  );
};
