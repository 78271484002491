import { Box, Header, Table, Link } from '@amzn/awsui-components-react/polaris';
import { ReactElement, ReactNode } from 'react';
import { SERVICE_STRINGS } from '../../../constants/UIStrings';
import {
  getFeedbackFolderLink,
  getFeedbackSIMFolder,
  getListOfPathSlugs,
} from '../utils';

interface PackagesLinks {
  pathSlugs: string[];
  altPathslug?: string;
  feedbackSIM: string[];
  feedbackCTI: string[];
}

interface PackageLinkTableData {
  key: ReactElement | string;
  value: ReactNode;
}

/**
 * 'Additional Details' table in service view.
 */
function AdditionalDetailsTable({
  pathSlugs,
  altPathslug,
  feedbackSIM,
  feedbackCTI,
}: PackagesLinks): ReactElement {
  // TODO: Verify fields that should be displayed here
  const packageLinks: PackageLinkTableData[] = [];

  packageLinks.push({
    key: 'Path slugs',
    value: getListOfPathSlugs(pathSlugs),
  });
  if (altPathslug)
    packageLinks.push({ key: 'Alternate path slug', value: altPathslug });
  packageLinks.push({
    key: 'CTI',
    value: getFeedbackFolderLink(feedbackCTI),
  });
  packageLinks.push({
    key: 'SIM',
    value: getFeedbackSIMFolder(feedbackSIM),
  });
  packageLinks.push({
    key: 'Documentation landing',
    value: altPathslug ? (
      <Link
        external
        href={`https://docs.aws.amazon.com/${altPathslug}`}
        externalIconAriaLabel={'Open documentation landing page'}
      >
        {`https://docs.aws.amazon.com/${altPathslug}`}
      </Link>
    ) : (
      <>-</>
    ),
  });

  return (
    <Table
      header={
        <Header
          counter={`(${packageLinks.length})`}
          description={SERVICE_STRINGS['Additional details']}
        >
          Additional details
        </Header>
      }
      columnDefinitions={[
        {
          id: 'key',
          header: 'Key',
          cell: (item) => item.key || '-',
        },
        {
          id: 'value',
          header: 'Value',
          cell: (item) => item.value || '-',
        },
      ]}
      items={packageLinks}
      loadingText='Loading resources'
      empty={
        <Box textAlign='center' color='inherit'>
          <b>No additional resources found</b>
          <Box padding={{ bottom: 's' }} variant='p' color='inherit'>
            This service does not currently have any additional resources.
          </Box>
        </Box>
      }
    />
  );
}

export default AdditionalDetailsTable;
