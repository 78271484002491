import { Header, Table } from '@amzn/awsui-components-react/polaris';
import { getPackageLink } from '../utils';
import { EmptyStateNoButton } from '../../ui/EmptyState';
import PointOfContactElement from '../ui/pointOfContactSection';
import { PackageData } from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import { ReactElement } from 'react';

interface PackageList {
  serviceId: string;
  packageDataList: PackageData[];
}

const PackageListTable = ({
  serviceId,
  packageDataList,
}: PackageList): ReactElement => (
  <Table
    columnDefinitions={[
      {
        id: 'package',
        header: 'Package',
        cell: (item: PackageData) =>
          getPackageLink(serviceId, item.packageName) ?? '-',
      },
      {
        id: 'pointOfContact',
        header: 'Point of Contact',
        cell: (item: PackageData) => {
          return <PointOfContactElement packageData={item} />;
        },
      },
    ]}
    items={packageDataList}
    loadingText='Loading resources'
    empty={
      <EmptyStateNoButton
        title='Failed to load packages'
        subtitle='The reason why you are seeing this is either due to this service currently does not have any related packages or there is an error occured when loading the page, please refresh the page see if it fixed the issue.'
        action={null}
      />
    }
    header={
      <Header counter={`(${packageDataList.length})`}>Related packages</Header>
    }
  />
);

export default PackageListTable;
