import {
  CreateEntitiesCommand,
  GetOfferingCommand,
  Offering,
} from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import formStyles from '../Form.module.sass';
import {
  Box,
  Button,
  Form,
  Modal,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { LongNameEntityIdField, ShortNameEntityIdField } from '../fields';
import { callApi } from '../../../client';
import { useAuthState } from '../../../authentication';
import { EntityMap } from '../EntityMap';

export const CreateEntitiesForm = ({
  onClose,
}: {
  onClose?: () => void;
}): ReactElement => {
  const [notice, setNotice] = useState<{
    title: string;
    body: ReactNode;
    callback?: () => void;
  }>();
  const { token } = useAuthState();
  // const [names, setNames] = useState<OfferingNames>();
  const [offeringQueried, setOfferingQueried] = useState(false);
  const [offering, setOffering] = useState<Offering>();
  const rawOfferingId = /Moniker\/offerings\/([^/]+)\/?/.exec(
    location.hash
  )?.[1];
  const offeringId = rawOfferingId
    ? decodeURIComponent(rawOfferingId)
    : undefined;
  const [shortNameEntityId, setShortNameEntityId] = useState('');
  const [longNameEntityId, setLongNameEntityId] = useState('');
  const [longNameEntityIdValid, setLongNameEntityIdValid] = useState(false);
  const [shortNameEntityIdValid, setShortNameEntityIdValid] = useState(false);
  const [valid, setValid] = useState(false);
  useEffect(() => {
    setValid(shortNameEntityIdValid && longNameEntityIdValid);
  }, [shortNameEntityIdValid, longNameEntityIdValid]);
  useEffect(() => {
    if (!offeringId || offering || offeringQueried || !token) return;
    setOfferingQueried(true);
    callApi(new GetOfferingCommand({ offeringId }), token)
      .then((e) => {
        setOffering(e.offering);
      })
      .catch(apiFailed);
  }, [offeringId, offering, offeringQueried, token]);
  const close = () => {
    setShortNameEntityId('');
    setLongNameEntityId('');
    onClose?.();
  };
  const apiFailed = (e: unknown) =>
    setNotice({ title: 'Failed', body: String(e) });
  const noticeCallback = () => {
    setNotice(undefined);
    notice?.callback?.();
  };
  const submit = () =>
    offeringId &&
    token &&
    callApi(
      new CreateEntitiesCommand({
        offeringNames: { shortNameEntityId, longNameEntityId, offeringId },
      }),
      token
    )
      .then((e) =>
        setNotice({
          title: 'Entities created!',
          body: <EntityMap entities={e.entityIdentifierList} />,
          callback: () => window.location.reload(),
        })
      )
      .catch((err) =>
        setNotice({ title: 'Error creating entities', body: String(err) })
      );
  return (
    <Form
      variant='full-page'
      className={formStyles.Form}
      header={
        <Box variant='h2'>
          Create entities for {offering?.offeringName ?? 'an offering'}
        </Box>
      }
      actions={
        <SpaceBetween size='s' direction='horizontal'>
          <Button onClick={close}>Cancel</Button>
          <Button variant='primary' disabled={!valid} onClick={submit}>
            Submit
          </Button>
        </SpaceBetween>
      }
    >
      <SpaceBetween direction='vertical' size='m' className={formStyles.column}>
        <ShortNameEntityIdField
          required={true}
          value={shortNameEntityId}
          onChange={setShortNameEntityId}
          onValidate={setShortNameEntityIdValid}
        />
        <LongNameEntityIdField
          required={true}
          value={longNameEntityId}
          onChange={setLongNameEntityId}
          onValidate={setLongNameEntityIdValid}
        />
      </SpaceBetween>
      <Modal
        onDismiss={noticeCallback}
        header={<Box variant='h2'>{notice?.title}</Box>}
        visible={Boolean(notice)}
        size='medium'
        footer={
          <SpaceBetween size='m' direction='horizontal' alignItems='end'>
            <Button variant='primary' onClick={noticeCallback}>
              OK
            </Button>
          </SpaceBetween>
        }
      >
        {notice?.body}
      </Modal>
    </Form>
  );
};
