import { FormField, Select } from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { ReactElement, useEffect, useState } from 'react';
import { FieldProps } from '../FieldProps';

const empty: OptionDefinition = {
  label: 'Select...',
};
const no: OptionDefinition = {
  label: 'No',
};
const yes: OptionDefinition = {
  label: 'Yes',
};

export const PrefixRequiredField = (
  props: FieldProps<boolean | undefined, true>
): ReactElement => {
  const [valid, setValid] = useState(false);
  useEffect(() => setValid(typeof props.value === 'boolean'), [props.value]);
  useEffect(() => props.onValidate?.(valid), [valid]);
  return (
    <FormField label='Prefix required'>
      {props.old && props.old !== props.value && (
        <Select
          disabled={props.disabled}
          options={[empty, no, yes]}
          selectedOption={
            typeof props.old === 'boolean' ? (props.old ? yes : no) : empty
          }
          placeholder='Empty'
        />
      )}
      <Select
        disabled={props.disabled}
        options={[empty, no, yes]}
        selectedOption={
          typeof props.value === 'boolean' ? (props.value ? yes : no) : empty
        }
        invalid={!props.disabled && !valid}
        placeholder='Select...'
        onChange={(e) => {
          const { label } = e.detail.selectedOption;
          const v = label !== 'Select...';
          props.onChange?.(v ? (label === 'Yes' ? true : false) : undefined);
        }}
      />
    </FormField>
  );
};
