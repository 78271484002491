import { OwnershipFieldsProps } from '../../../interfaces';
import {
  Box,
  Button,
  Grid,
  Input,
  Select,
  Header,
  StatusIndicator,
  BoxProps,
} from '@amzn/awsui-components-react';
import { ReactElement } from 'react';

const OwnershipFields = ({
  role,
  email,
  ownerKey,
  owners,
  updateOwners,
  existingOwner,
  isServicePage,
  isInheritedPointOfContact,
}: OwnershipFieldsProps): ReactElement => {
  const roleOptions = getRoleOptionsForDisplay();
  const disableRemovebutton =
    !isServicePage && role !== roleOptions.pointOfContact.value;

  const infoFieldFlag =
    role === roleOptions.pointOfContact.value && isInheritedPointOfContact;

  const packagePOCOverridingServiceText =
    'This point of contact change only affects this package. Check the Service Detail Page for Point of contact of the service.';
  const indicatorDisplay: BoxProps.Display = infoFieldFlag ? 'block' : 'none';

  return (
    <>
      <Grid
        disableGutters
        gridDefinition={[{ colspan: 4 }, { colspan: 5 }]}
        key={`grid${ownerKey}`}
      >
        <Box margin={{ right: 'xs' }}>
          <Select
            disabled={true}
            options={Object.values(roleOptions)}
            selectedOption={roleOptions[role] || null}
          />
        </Box>
        <Input disabled={true} value={email} />
        {existingOwner && ''}
        {!existingOwner && (
          <Button
            disabled={disableRemovebutton}
            iconName='status-negative'
            variant='link'
            onClick={
              owners &&
              (() => {
                delete owners[ownerKey];
                updateOwners({ ...owners });
              })
            }
          >
            Remove
          </Button>
        )}
        <Box
          margin={{ top: 'xxxs' }}
          padding={{ top: 'xxs' }}
          display={indicatorDisplay}
        >
          <StatusIndicator type='warning'>Package-lvl Override</StatusIndicator>
        </Box>
      </Grid>
      <Box display={indicatorDisplay}>
        <Header description={packagePOCOverridingServiceText}></Header>
      </Box>
    </>
  );
};

export function getRoleOptions(
  isServicePage: boolean,
  isPOCExist: boolean
): RoleData {
  if (isServicePage) {
    if (isPOCExist) {
      return {
        writers: getRoleOptionsForDisplay().writers,
        editors: getRoleOptionsForDisplay().editors,
        writerManager: getRoleOptionsForDisplay().writerManager,
      };
    }
    return getRoleOptionsForDisplay();
  } else {
    return {
      pointOfContact: getRoleOptionsForDisplay().pointOfContact,
    };
  }
}
type Role = 'writers' | 'editors' | 'writerManager' | 'pointOfContact';
type RoleData = Partial<Record<Role, { label: string; value: string }>>;
function getRoleOptionsForDisplay() {
  return {
    writers: { label: 'Writer', value: 'writers' },
    editors: { label: 'Editor', value: 'editors' },
    writerManager: { label: "Writer's Manager", value: 'writerManager' },
    pointOfContact: { label: 'Point of contact', value: 'pointOfContact' },
  };
}

export default OwnershipFields;
