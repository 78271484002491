import FormField from '@amzn/awsui-components-react/polaris/form-field';
import React, { ReactElement } from 'react';

interface ValueWithDescriptionProps {
  label: string;
  children?: React.ReactNode;
  description?: string;
}

const ValueWithLabel = ({
  label,
  children,
  description,
}: ValueWithDescriptionProps): ReactElement => (
  <>
    <FormField label={<b>{label}</b>} description={description} />
    <div>{children}</div>
  </>
);

export default ValueWithLabel;
