import { Route, useRouteMatch, Switch } from 'react-router-dom';
import ServiceDetail from './ServiceDetail';
import { useState, useEffect, ReactElement } from 'react';
import ServicesTable from './ui/ServicesTable';
import { getServiceDataMap } from './utils';
import { useAuthState } from '../../authentication';
import EditService from './EditService';
import { useLocation } from 'react-router';
import { ErrorGoToHomepage } from '../ui/ErrorLoadingPage';
import { PackageListAPI } from './apiFunctions/CallPackageListAPI';
import {
  AwsService,
  GetServiceInfoCommand,
  ListServicesCommand,
  PackageData,
} from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import { callApi } from '../../client';

const ServiceList = (): ReactElement => {
  // TODO: Remove the any type below when calling the the real APIs
  const [fetchData, setFetchData] = useState(true);
  const [servicesData, setServicesData] = useState<AwsService[]>([]);
  const [serviceDataMap, setServiceDataMap] = useState<Map<string, AwsService>>(
    new Map()
  );
  const [servicesDataLoading, setServicesDataLoading] = useState(false);
  const [serviceDetailData, setServiceDetailData] = useState<AwsService>();
  const [serviceDetailDataNotFound, setServiceDetailDataNotFound] =
    useState<boolean>();
  const [serviceIDFromUrl, setServiceIDFromUrl] = useState('');
  const [isServiceList, setIsServiceList] = useState(false);

  const [packageDataList, setPackageDataList] = useState<PackageData[]>([]);

  const { isAuthenticated, token, packageInfoPermissionsGroup } =
    useAuthState();
  const location = useLocation();
  const { path } = useRouteMatch();
  async function callGetServiceInfoAPI() {
    if (token && fetchData) {
      setServiceDataMap(new Map());
      setServicesData([]);
      setServicesDataLoading(true);
      const decodedServiceIDFromUrl = decodeURIComponent(serviceIDFromUrl);
      try {
        const result = await callApi(
          new GetServiceInfoCommand({ serviceId: decodedServiceIDFromUrl }),
          token
        );
        const parsedServices = [result.serviceData];
        setServicesData(parsedServices);
        setServiceDataMap(getServiceDataMap(parsedServices));
      } catch (err) {
        console.log('error with FETCH', err);
      }
      setServicesDataLoading(false);
    }
  }
  async function callServicesAPI() {
    if (token && fetchData) {
      setServiceDataMap(new Map());
      setServicesData([]);
      setServicesDataLoading(true);
      try {
        const { services } = await callApi(
          new ListServicesCommand({ pageSize: 2000 }),
          token
        );
        setServicesData(services);
        setServiceDataMap(getServiceDataMap(services));
      } catch (err) {
        console.log('error with FETCH', err);
      }
      setServicesDataLoading(false);
    }
  }

  useEffect(() => {
    const splitPath = location.pathname.split('/');
    splitPath.length === 3
      ? setServiceIDFromUrl(splitPath[2])
      : setServiceIDFromUrl('');
    setIsServiceList(splitPath.length === 2);
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const listPackageAPI = new PackageListAPI();

    async function fetchPackageListData() {
      if (serviceIDFromUrl && isAuthenticated && fetchData && token) {
        void callGetServiceInfoAPI();

        await listPackageAPI.invokeListPackageAPI(
          isAuthenticated,
          serviceIDFromUrl,
          token
        );

        setPackageDataList(listPackageAPI.getPackageDataList());
      }
    }

    void fetchPackageListData();
  }, [isAuthenticated, fetchData, serviceIDFromUrl]);

  useEffect(() => {
    if (isServiceList && isAuthenticated && fetchData) {
      void callServicesAPI();
    }
  }, [isAuthenticated, fetchData, isServiceList]);

  useEffect(() => {
    if (!servicesDataLoading && serviceDataMap) {
      if (serviceIDFromUrl !== '') {
        const decodedServiceIDFromUrl = decodeURIComponent(serviceIDFromUrl);
        if (serviceDataMap.has(decodedServiceIDFromUrl)) {
          setServiceDetailData(serviceDataMap.get(decodedServiceIDFromUrl));
          setServiceDetailDataNotFound(false);
        } else {
          setServiceDetailDataNotFound(true);
        }
      }
    }
  }, [servicesDataLoading, serviceDataMap, serviceIDFromUrl]);

  // Using nested routes so service data can persist through the service pages using state
  // https://reactrouter.com/web/example/nesting
  // The `path` lets us build <Route> paths that are relative to the parent route

  return (
    <Switch>
      <Route exact path={path}>
        <ServicesTable data={servicesData} loading={servicesDataLoading} />
      </Route>
      <Route path={`${path}/:serviceId/edit`}>
        {packageInfoPermissionsGroup == 'info-editor' ? (
          <EditService
            serviceData={serviceDetailData}
            serviceDataNotFound={serviceDetailDataNotFound}
            loading={servicesDataLoading}
            setFetchDataInServiceList={setFetchData}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            packageDataList={packageDataList}
          />
        ) : (
          <ErrorGoToHomepage />
        )}
      </Route>
      <Route path={`${path}/:serviceId`}>
        <ServiceDetail
          serviceData={serviceDetailData}
          serviceDataNotFound={serviceDetailDataNotFound}
          loading={servicesDataLoading}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          packageDataList={packageDataList}
        />
      </Route>
    </Switch>
  );
};

export default ServiceList;
