import React, { ReactElement } from 'react';
import {
  ExpandableSection,
  Header,
  Container,
  ColumnLayout,
  SpaceBetween,
  Link,
} from '@amzn/awsui-components-react/polaris';
import ValueWithLabel from '../../ui/ValueWithLabel';
import PackageBuildsTable from './PackageBuildsTable';
import GitHubInfoTable from './GitHubInfoTable';
import { PackageGuide } from '../../../interfaces';

interface PackageGuideProps {
  guide: PackageGuide;
  key: string;
  packagePathSlug: string;
}

function PackageGuides({
  guide,
  packagePathSlug,
}: PackageGuideProps): ReactElement {
  const guideUrl = `https://docs.aws.amazon.com/${packagePathSlug}/${guide.version}/${guide.pathslug}`;

  return (
    <ExpandableSection
      variant='container'
      headerText={guide.title}
      headerDescription={`Guide pathslug: ${guide.pathslug}`}
    >
      <SpaceBetween size='l'>
        <Container header={<Header variant='h2'>Naming conventions</Header>}>
          <ColumnLayout columns={4} variant='text-grid'>
            <ValueWithLabel label='API'>{guide.api ?? '-'}</ValueWithLabel>
            <ValueWithLabel label='Basename'>
              {guide.basename ?? '-'}
            </ValueWithLabel>
            <ValueWithLabel label='Guide pathslug'>
              {guide.pathslug ?? '-'}
            </ValueWithLabel>
            <ValueWithLabel label='Title'>{guide.title ?? '-'}</ValueWithLabel>
            <ValueWithLabel label='Source'>
              {guide.source ?? '-'}
            </ValueWithLabel>
            <ValueWithLabel label='Profile'>
              {guide.profile?.os ?? '-'}
            </ValueWithLabel>
            <ValueWithLabel label='Version'>
              {guide.version ?? '-'}
            </ValueWithLabel>
            {guide.source === 'latest/help_panel' ? (
              ''
            ) : (
              <ValueWithLabel label='Live URL'>
                <Link
                  href={guideUrl}
                  external
                  externalIconAriaLabel='Opens in a new tab'
                >
                  {guideUrl}
                </Link>
              </ValueWithLabel>
            )}
          </ColumnLayout>
        </Container>
        <PackageBuildsTable builds={guide.builds} />
        <GitHubInfoTable github={guide.github} />
      </SpaceBetween>
    </ExpandableSection>
  );
}

export default PackageGuides;
