import { NameAlias } from '@amzn/awsdev-docs-virtual-smiley-typescript-client';

export const nameAliasToOption = ({
  firstName,
  lastName,
  alias,
}: NameAlias): { value?: string; label: string } => ({
  value: alias,
  label: `${String(firstName)} ${String(lastName)} - ${String(alias)}`,
});
