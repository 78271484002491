import {
  Container,
  FormField,
  Select,
  SpaceBetween,
} from '@amzn/awsui-components-react/polaris';
import { Empty } from './f2/Empty';
import { Validatable } from './Validatable';
import { FieldProps } from './f2/FieldProps';
import { ReactElement, useEffect, useState } from 'react';
import {
  GlobalNamesInText,
  RegionalNameInformationInText,
  RegionProfiledNamesInText,
} from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
const emptyOption = {
  value: 'empty',
  label: 'Select region profiling...',
};
const allOption = {
  value: 'ALL',
  label: 'ALL',
};
const restOption = {
  value: 'REST',
  label: 'BJS / NON_BJS / UNIVERSAL',
};
const options = [emptyOption, allOption, restOption];
export const RegionMapEditor = ({
  disabled,
  value,
  onChange,
  onValidate,
  required,
}: FieldProps<
  RegionalNameInformationInText | undefined,
  true
>): ReactElement => {
  const [valid, setValid] = useState(false);
  const [dropValid, setDropValid] = useState(false);
  useEffect(() => {
    setDropValid(!required || Boolean(value));
    setValid(
      (!required && !value) ||
        Boolean(
          value &&
            Object.values(value.globalNames ?? value.profiledNames ?? []).every(
              (val: string) => val?.trim().length
            )
        )
    );
  }, [value, required]);
  useEffect(() => onValidate?.(valid), [valid]);
  const variant = value && (Object.keys(value)[0] as keyof typeof value);
  const names = value?.globalNames ?? value?.profiledNames;
  return (
    <SpaceBetween direction={'vertical'} size={'m'}>
      {!disabled && (
        <Select
          invalid={!dropValid && !disabled}
          selectedOption={
            !value || !Object.keys(value).length
              ? emptyOption
              : value.globalNames
              ? allOption
              : restOption
          }
          disabled={disabled}
          options={options}
          onChange={({
            detail: {
              selectedOption: { value },
            },
          }) =>
            onChange?.(
              value === 'ALL'
                ? {
                    globalNames: {
                      all: '',
                    },
                  }
                : value === 'REST'
                ? {
                    profiledNames: {
                      bjs: '',
                      allExceptBjs: '',
                      universal: '',
                    },
                  }
                : undefined
            )
          }
        />
      )}
      {value && Object.keys(value).length > 0 ? (
        <Container variant='stacked'>
          <SpaceBetween size='m' direction='vertical'>
            {names &&
              Object.entries(names).map(
                ([k, v]: [
                  string,
                  (
                    | GlobalNamesInText[keyof GlobalNamesInText]
                    | RegionProfiledNamesInText[keyof RegionProfiledNamesInText]
                  )
                ]) => (
                  <FormField label={k}>
                    <Validatable
                      value={v}
                      invalid={!v.trim().length}
                      disabled={disabled}
                      onChange={(e) =>
                        variant &&
                        variant !== '$unknown' &&
                        onChange?.({
                          ...value,
                          [variant]: {
                            ...value[variant],
                            [k]: e.detail.value,
                          },
                        })
                      }
                    />
                  </FormField>
                )
              )}
          </SpaceBetween>
        </Container>
      ) : (
        disabled && <Empty />
      )}
    </SpaceBetween>
  );
};
