import {
  Offering,
  OfferingReview,
} from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
const hiddenFields = ['dataSource'] as const;
type HiddenField = typeof hiddenFields[number];
type OmitHidden<T> = Omit<T, HiddenField>;
export const offeringPropList: Exclude<keyof Offering, HiddenField>[] = [
  'offeringId',
  'offeringName',
  'prefixRequired',
  'brandPrefix',
  'regions',
  'shortDescription',
  'launchStatus',
  'doNotUseName',
  'deprecatedName',
  'generalNotes',
  'resourceExamples',
  'docsCategories',
  'marketingCategories',
  'type',
  'offeringId',
  'ripServiceId',
  'extraLongName',
  'longName',
  'shortName',
  'extraShortName',
  'localizationGuidance',
  'localizationNotes',
  'localizationWords',
  'offeringStatus',
  'createdAt',
  'modifiedAt',
  'createdBy',
  'modifiedBy',
  'approvedBy',
];
export const offeringReviewPropList: (keyof OmitHidden<OfferingReview>)[] = [
  'offeringId',
  'offeringName',
  'prefixRequired',
  'brandPrefix',
  'regions',
  'shortDescription',
  'launchStatus',
  'doNotUseName',
  'deprecatedName',
  'generalNotes',
  'resourceExamples',
  'docsCategories',
  'marketingCategories',
  'type',
  'locGuidanceRequired',
  'locSubscribersList',
  'offeringReviewId',
  'offeringId',
  'offeringReviewStatus',
  'extraLongName',
  'longName',
  'shortName',
  'extraShortName',
  'ripServiceId',
  'localizationGuidance',
  'localizationNotes',
  'localizationWords',
  'createdAt',
  'modifiedAt',
  'createdBy',
  'reviewedBy',
  'userPermissions',
];
