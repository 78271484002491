import { StatusIndicator } from '@amzn/awsui-components-react/polaris';
import { Box } from '@amzn/awsui-components-react';
import { PackageData } from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import { ReactElement } from 'react';

interface pointOfContactElementProps {
  packageData: PackageData;
}

const PointOfContactElement = ({
  packageData,
}: pointOfContactElementProps): ReactElement => {
  const statusIndicatorFlag = packageData.inheritedPointOfContact;
  const noPOCIndicatorFlag = packageData.pointOfContact === undefined;

  return (
    <Box>
      {packageData.pointOfContact} {}
      {statusIndicatorFlag ? (
        <></>
      ) : noPOCIndicatorFlag ? (
        <>-</>
      ) : (
        <StatusIndicator type='info'>Package-level override</StatusIndicator>
      )}
    </Box>
  );
};

export default PointOfContactElement;
