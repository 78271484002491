import { getApiInvokeUrl, isDevDesk, isLocalHost } from '../utils';
import { PackageData } from '@amzn/awsdev-docs-virtual-smiley-typescript-client';

const apiStatusEnum = {
  loading: 'loading',
  success: 'success',
  failure: 'failure',
} as const;
type WrapperStatus = typeof apiStatusEnum[keyof typeof apiStatusEnum] | 'idle';

export class PackageInfoAPI {
  status: WrapperStatus;
  packageData: PackageData | undefined;

  constructor() {
    this.status = 'idle';
    this.packageData = undefined;
  }

  updateStatus(status?: string): void {
    if (status === apiStatusEnum.success) {
      this.status = apiStatusEnum.success;
    } else if (status === apiStatusEnum.loading) {
      this.status = apiStatusEnum.loading;
    } else {
      this.status = apiStatusEnum.failure;
    }
  }

  getStatus(): WrapperStatus {
    return this.status;
  }

  getPackageData(): PackageData | undefined {
    return this.packageData;
  }

  async InvokePackageAPI(
    isAuthenticated: boolean,
    packageId: string,
    token: string | null
  ): Promise<void> {
    if (isAuthenticated || isDevDesk()) {
      this.updateStatus(apiStatusEnum.loading);

      const decodedPackageId = decodeURIComponent(packageId);
      const requestBody = `{"packageName":"${decodedPackageId}"}`;
      const invokeUrl = getApiInvokeUrl() + '/info/getPackageInfo';
      const requestOptions: Record<string, unknown> = {
        headers: {
          'x-lambda-authorizer-token': token,
          'Content-Type': 'application/json',
        },
        body: requestBody,
        method: 'POST',
      };

      try {
        const requestConfig =
          isDevDesk() || isLocalHost()
            ? { url: './public/packageData.json', init: undefined }
            : { url: invokeUrl, init: requestOptions };
        const response = await fetch(requestConfig.url, requestConfig.init);

        const rawResult = await response.json();
        this.packageData = rawResult.packageData;
        this.updateStatus(apiStatusEnum.success);
        console.log(this.getStatus());
        //setGuides(pkg.guides ? Object.values(JSON.parse(pkg.guides)) : []);
      } catch (err) {
        console.log('error with FETCH', err);
        this.updateStatus();
      }
    }
  }
}

export class PackageListAPI {
  status: WrapperStatus;
  packageDataList: PackageData[];

  constructor() {
    this.status = 'idle';
    this.packageDataList = [];
  }

  updateStatus(status?: WrapperStatus): void {
    if (status === apiStatusEnum.success) {
      this.status = apiStatusEnum.success;
    } else if (status === apiStatusEnum.loading) {
      this.status = apiStatusEnum.loading;
    } else {
      this.status = apiStatusEnum.failure;
    }
  }

  getStatus(): WrapperStatus {
    return this.status;
  }

  getPackageDataList(): PackageData[] {
    return this.packageDataList;
  }

  async invokeListPackageAPI(
    isAuthenticated: boolean,
    serviceId: string,
    token: string | null
  ): Promise<void> {
    if (isAuthenticated || isDevDesk()) {
      this.updateStatus(apiStatusEnum.loading);
      const pageSize = 1000; //set as 1k as of now
      const decodedServiceId = decodeURIComponent(serviceId);
      const requestBody = `{
          "serviceId":"${decodedServiceId}",
          "pageSize":${pageSize}
        }`;
      const invokeUrl = getApiInvokeUrl() + '/info/listPackages';
      const requestOptions: Record<string, unknown> = {
        headers: {
          'x-lambda-authorizer-token': token,
          'Content-Type': 'application/json',
        },
        body: requestBody,
        method: 'POST',
      };

      try {
        const requestConfig =
          isDevDesk() || isLocalHost()
            ? { url: './public/mockPackageDataList.json', init: undefined }
            : { url: invokeUrl, init: requestOptions };
        const response = await fetch(requestConfig.url, requestConfig.init);

        const rawResult = await response.json();
        const cookedResult = rawResult.packages;

        this.packageDataList = cookedResult;
        this.updateStatus(apiStatusEnum.success);
        console.log(this.getStatus());
        //setGuides(pkg.guides ? Object.values(JSON.parse(pkg.guides)) : []); //Is required under some pages, need to apply this when using this function as global api call
      } catch (err) {
        console.log('error with FETCH', err);
        this.updateStatus();
      }
    }
  }
}
