import { FormField, Input } from '@amzn/awsui-components-react';
import { ReactElement, useEffect, useState } from 'react';
import { FieldProps } from '../FieldProps';

export const LocalizationNotesField = (
  props: FieldProps<string, true>
): ReactElement => {
  const [valid, setValid] = useState(true);
  useEffect(() => setValid(/^.{0,255}$/.test(props.value)), [props.value]);
  useEffect(() => props.onValidate?.(valid), [valid]);
  return (
    <FormField label='Localization notes'>
      {typeof props.old === 'string' && props.old !== props.value && (
        <Input disabled={true} value={props.old} />
      )}
      <Input
        disabled={props.disabled}
        value={props.value}
        invalid={!valid}
        onChange={(e) => {
          props.onChange?.(e.detail.value);
        }}
      />
    </FormField>
  );
};
