import { performUserAuthentication } from './utils';
import { useAuthDispatch } from './context';
import { ReactElement, useEffect } from 'react';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';

/**
 * Purpose of this component is to render a loading screen then perform
 * Cognito authentication
 */
const AuthSpinner = (): ReactElement => {
  const dispatch = useAuthDispatch();

  /**
   * Have to use a workaround when using async functions in useEffect hooks
   * https://stackoverflow.com/questions/53332321/react-hook-warnings-for-async-function-in-useeffect-useeffect-function-must-ret
   */
  useEffect(
    () =>
      void performUserAuthentication().then((payload) =>
        'error' in payload
          ? console.error(
              'Failed to perform user authentication',
              payload.error
            )
          : dispatch({ type: 'LOGIN', payload })
      ),
    []
  );
  return <Spinner size='large' />;
};

export default AuthSpinner;
