import { ExportOfferingsCommand } from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import { Button, StatusIndicator } from '@amzn/awsui-components-react';
import { ReactElement, useCallback, useState } from 'react';
import { useAuthState } from '../authentication';
import { callApi } from '../client';
import { NoTokenError } from '../errors';
type ExportStatus = 'success' | 'loading' | 'error' | 'idle';
const texts = {
  success: 'Exported',
  loading: 'Loading',
  error: 'Error',
};
export const ExportOfferingList = (): ReactElement => {
  const { token } = useAuthState();
  const [status, setStatus] = useState<ExportStatus>('idle');
  const reset = useCallback(() => setStatus('idle'), []);
  const download = useCallback(() => {
    if (!token) throw new NoTokenError();
    setStatus('loading');
    callApi(new ExportOfferingsCommand({}), token)
      .then((res) => {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(
          new Blob([res.output], { type: 'application/octet-stream' })
        );
        a.setAttribute('target', '_blank');
        a.download = `moniker_offerings_${
          new Date().toISOString().split('.')[0]
        }.csv`;
        a.click();
        URL.revokeObjectURL(a.href);
        setStatus('success');
        setTimeout(reset, 2000);
      })
      .catch((e) => {
        setStatus('error');
        console.log('Error', e);
      });
  }, [token]);
  return (
    <Button onClick={download} disabled={status !== 'idle'}>
      {status !== 'idle' ? (
        <StatusIndicator type={status}>{texts[status]}</StatusIndicator>
      ) : (
        'Export all'
      )}
    </Button>
  );
};
