import { OfferingReviewStatus } from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import {
  StatusIndicator,
  StatusIndicatorProps,
} from '@amzn/awsui-components-react';
import { ReactElement } from 'react';

const statusMap: Record<OfferingReviewStatus, StatusIndicatorProps> = {
  APPROVED: { type: 'success', children: 'Approved' },
  CANCELLED: { type: 'stopped', children: 'Stopped' },
  PENDING_LOC_REVIEW: { type: 'pending', children: 'Pending loc review' },
  PENDING_REVIEWER: { type: 'pending', children: 'Pending review' },
  REJECTED: { type: 'error', children: 'Rejected' },
} as const;

export const OfferingReviewStatusIndicator = ({
  status,
}: {
  status: OfferingReviewStatus;
}): ReactElement => <StatusIndicator {...statusMap[status]} />;
