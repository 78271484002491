import React, { ReactElement } from 'react';
import { Header, Table, Box } from '@amzn/awsui-components-react/polaris';
import { PackageGuideBuilds } from '../../../interfaces';

interface PackageBuildsTableProps {
  builds: PackageGuideBuilds | undefined;
}

function PackageBuildsTable({ builds }: PackageBuildsTableProps): ReactElement {
  interface buildItem {
    key: string;
    value: string;
  }

  const items: buildItem[] = [];

  if (builds) {
    if (builds.html?.rss?.title)
      items.push({ key: 'RSS Title', value: builds.html.rss.filename });

    if (builds.html?.rss?.filename)
      items.push({ key: 'RSS Filename', value: builds.html.rss.filename });

    if (builds.kindle?.asin)
      items.push({ key: 'Kindle ASIN', value: builds.kindle?.asin });

    if (builds.markdown?.includeHtmlProfiling)
      items.push({
        key: 'Markdown HTML profiling',
        value: builds.markdown?.includeHtmlProfiling,
      });

    if (builds.asciidoc?.includeHtmlProfiling)
      items.push({
        key: 'Asciidoc HTML profiling',
        value: builds.asciidoc?.includeHtmlProfiling,
      });

    if (builds.helpPanel?.includeHtmlProfiling)
      items.push({
        key: 'Help panel HTML profiling',
        value: builds.helpPanel?.includeHtmlProfiling,
      });
  }

  return (
    <Table
      columnDefinitions={[
        {
          id: 'key',
          header: 'Key',
          cell: (item) => item.key ?? '-',
        },
        {
          id: 'value',
          header: 'Value / link',
          cell: (item) => item.value ?? '-',
        },
      ]}
      items={items}
      loadingText='Loading builds'
      empty={
        <Box textAlign='center' color='inherit'>
          <b>No builds</b>
          <Box padding={{ bottom: 's' }} variant='p' color='inherit'>
            No builds to display.
          </Box>
        </Box>
      }
      header={<Header counter={`(${items.length})`}>Builds </Header>}
    />
  );
}

export default PackageBuildsTable;
