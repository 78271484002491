import { DocumentationCategory } from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import { FormField, Multiselect } from '@amzn/awsui-components-react';
import { ReactElement, useState, useEffect } from 'react';
import { FieldProps } from '../FieldProps';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';

export const PromoteCategoriesField = (
  props: FieldProps<DocumentationCategory[], false> & {
    availableOptions: DocumentationCategory[];
    onChange: (selectedCategories: DocumentationCategory[]) => void;
    selectedCategories?: DocumentationCategory[];
  }
): ReactElement => {
  const [selectedOptions, setSelectedOptions] = useState<any>(
    props.selectedCategories
      ? props.selectedCategories.map((category) => ({
          value: category.id,
          label: category.categoryName,
        }))
      : []
  );
  const options = props.availableOptions.map((category) => ({
    value: category.id,
    label: category.categoryName,
  }));

  useEffect(() => {
    const newSelectedOptions = selectedOptions.filter((selected: any) =>
      props.availableOptions.some((option) => option.id === selected.value)
    );
    setSelectedOptions(newSelectedOptions);
  }, [props.availableOptions]);

  const handleChange = (detail: any) => {
    setSelectedOptions(detail.selectedOptions);
    const selectedCategories = detail.selectedOptions.map(
      (option: OptionDefinition) => ({
        id: option.value,
        categoryName: option.label,
      })
    );
    props.onChange(selectedCategories);
  };

  return (
    <FormField
      label='Promoted category'
      description='Select to move the service to the first position in a category on the documentation home page (MDLP).'
    >
      <Multiselect
        disabled={props.disabled}
        options={options}
        selectedOptions={options.filter((o) =>
          props.value.some((value) => value.id === o.value)
        )}
        onChange={({ detail }) => handleChange(detail)}
      />
    </FormField>
  );
};
