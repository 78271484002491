import { useAuthState } from '../../authentication';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { Spinner } from '@amzn/awsui-components-react/polaris';
import { useState, useEffect, ReactElement } from 'react';

import { ErrorGoToHomepage, ErrorRefreshPage } from '../ui/ErrorLoadingPage';
import { isDevDesk } from './utils';
import EditPackage from './EditPackage';
import PackageDetailData from './ui/PackageDetailData';
import {
  GetPackageInfoCommand,
  PackageData,
} from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import { callApi } from '../../client';

const PackageDetail = (): ReactElement => {
  const { isAuthenticated, token, packageInfoPermissionsGroup } =
    useAuthState();
  const { packageId } = useParams<{ packageId?: string }>();
  const [packageDataLoading, setPackageDataLoading] = useState<boolean>();
  const [packageData, setPackageData] = useState<PackageData>();
  const [fetchData, setFetchData] = useState(true);

  const { path } = useRouteMatch();
  // TODO: Add breadcrumb override for corresponding service once this has been added to the API response

  useEffect(() => {
    if ((isAuthenticated || isDevDesk()) && fetchData && packageId && token) {
      setPackageDataLoading(true);

      void callApi(
        new GetPackageInfoCommand({
          packageName: decodeURIComponent(packageId),
        }),
        token
      )
        .then((res) => setPackageData(res.packageData))
        .catch((err) => console.log('Error getting package info:', err))
        .finally(() => {
          setPackageDataLoading(false);
          setFetchData(false);
        });
    }
  }, [isAuthenticated, fetchData]);

  // Still retrieving data
  if (packageDataLoading) {
    return <Spinner size='large' />;
  }

  if (!packageDataLoading && packageData) {
    return (
      <Switch>
        <Route exact path={path}>
          <PackageDetailData packageData={packageData} />
        </Route>
        <Route path={`${path}/edit`}>
          {packageInfoPermissionsGroup == 'info-editor' ? (
            <EditPackage
              packageData={packageData}
              setFetchDataInPackageDetail={setFetchData}
            />
          ) : (
            <ErrorGoToHomepage />
          )}
        </Route>
      </Switch>
    );
  }

  // If we get to this point, there was some kind of error
  return <ErrorRefreshPage />;
};

export default PackageDetail;
