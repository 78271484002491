// https://quip-amazon.com/nwsaAKnRMbtb/Virtual-Smiley-UI-Strings

type Term = string;
type Definition = string;

export const SERVICE_STRINGS: Record<Term, Definition> = {
  'Naming conventions': 'As defined in RIP.',
  Visibility:
    'Denotes whether the AWS service serves external users (publicly released). Defined in RIP.',
  'Restricted status':
    'Boolean value that denotes whether a service should be viewable by all internal Amazon users. Used to restrict access to pre-release content.',
  'Region status':
    'Mapping of service release status in each AWS region. Defined in RIP.',
  'Additional details':
    'Additional details pulled from the build-info.xml files of each package under a service.',
  Ownership: 'Ownership details are manually maintained.',
  fundingStatus:
    'Boolean value denoting whether a service has been successfully funded.',
};

export const PACKAGE_STRINGS: Record<Term, Definition> = {
  'Naming conventions': 'Defined in RIP or pulled from build-info.xml file',
  Ownership: 'Ownership details are manually maintained.',
};
