import { ReactElement, ReactNode } from 'react';
import classnames from 'classnames';
import styles from './Route.module.sass';
const directions = ['left', 'top', 'right', 'bottom'];
type Direction = typeof directions[number];

// TODO: Trash my garbage Route system and use react-router like the rest of the site
export const Route = ({
  match,
  children,
  hash,
  from,
}: {
  match?: RegExp;
  children: ReactNode;
  hash: string;
  from?: Direction;
}): ReactElement =>
  !match || match.test(hash) ? (
    <div
      className={classnames(
        styles.Route,
        {
          [styles.visible]: !match || match.test(hash),
        },
        from ?? 'right'
      )}
    >
      <div>{children}</div>
    </div>
  ) : (
    <></>
  );
