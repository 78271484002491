import { Box, SpaceBetween, Link } from '@amzn/awsui-components-react';
import { ReactElement } from 'react';

const Home = (): ReactElement => {
  return (
    <SpaceBetween direction={'vertical'} size={'s'}>
      <Box
        variant='h1'
        padding={{ top: 'xl' }}
        fontSize='heading-xl'
        color='inherit'
      >
        Welcome
      </Box>
      <Box padding={{ bottom: 's' }} fontSize='body-m' color='inherit'>
        Virtual Smiley is a central service built by the TCX Engineering team to
        help writers, editors, and engineers automate and complete manual tasks
        within an easy-to-use web interface. Virtual Smiley includes several
        microservices that will connect data from various sources to simplify
        common tasks. You can read more about Virtual smiley
        <Link
          external
          externalIconAriaLabel='Opens in a new tab'
          href='https://quip-amazon.com/qTIhATpenNng/Virtual-Smiley-Writer-ManagerEditor-User-Guide'
        >
          {' '}
          here
        </Link>
      </Box>
    </SpaceBetween>
  );
};

export default Home;
