import { MarketingCategory } from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import { FormField, Multiselect } from '@amzn/awsui-components-react';
import { ReactElement } from 'react';
import { FieldProps } from '../FieldProps';

const options = Object.entries(MarketingCategory).map(
  ([, label]): { value: string; label: string } => ({
    value: label,
    label,
  })
);

const areEqual = (arr1: string[], arr2: string[]): boolean => {
  if (arr1.length !== arr2.length) return false;
  return arr1.every((value, index) => value === arr2[index]);
};

export const MarketingCategoriesField = (
  props: FieldProps<MarketingCategory[], false>
): ReactElement => {
  return (
    <FormField label='Marketing categories'>
      {props.old && !areEqual(props.old, props.value) && (
        <Multiselect
          disabled={true}
          options={options}
          selectedOptions={options.filter((o) =>
            props.old?.includes(o.value as MarketingCategory)
          )}
        />
      )}
      <Multiselect
        disabled={props.disabled}
        onChange={(e) =>
          props.onChange?.(
            e.detail.selectedOptions.map((o) => o.value as MarketingCategory)
          )
        }
        options={options}
        selectedOptions={options.filter((o) =>
          props.value.includes(o.value as MarketingCategory)
        )}
      />
    </FormField>
  );
};
