import {
  CollectionPreferences,
  CollectionPreferencesProps,
} from '@amzn/awsui-components-react';
import { ReactElement } from 'react';
import { fieldTitles } from './f2/fieldTitles';
export type Prefs = CollectionPreferencesProps.Preferences;

type Props = {
  fields: (keyof typeof fieldTitles)[];
  preferences: Prefs;
  setPreferences: React.Dispatch<React.SetStateAction<Prefs>>;
};
export const OfferingListCollectionPreferences = ({
  fields,
  preferences,
  setPreferences,
}: Props): ReactElement => {
  const options: { id: string; label: string }[] = [];
  for (const id of fields) {
    const label = fieldTitles[id];
    if (label) options.push({ id, label });
  }
  return (
    <CollectionPreferences
      onConfirm={({ detail }) =>
        setPreferences({
          ...preferences,
          pageSize: detail.pageSize,
          visibleContent: detail.visibleContent as string[],
        })
      }
      preferences={preferences}
      visibleContentPreference={{
        title: 'Select visible content',
        options: [
          {
            label: 'Columns: ',
            options,
          },
        ],
      }}
      pageSizePreference={{
        title: 'Select page size',
        options: [
          { value: 20, label: '20 services' },
          { value: 50, label: '50 services' },
        ],
      }}
      cancelLabel='Cancel'
      confirmLabel='Confirm'
      title='Preferences'
    />
  );
};
