import { PackageUpdates } from '../../interfaces';
import { Dispatch, ReactElement, useState } from 'react';

import {
  Box,
  Button,
  Form,
  Header,
  Modal,
  SpaceBetween,
  Spinner,
  TextContent,
} from '@amzn/awsui-components-react';
import EditOwners from './editComponents/EditOwners';
import { useAuthState } from '../../authentication';
import {
  PackageData,
  UpdatePackageCommand,
  UpdatePackageResponse,
} from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import { callApi } from '../../client';
import { NoTokenError } from '../../errors';

let disableSaveButtonFlag1 = false;
let disableSaveButtonFlag2 = false;
let disableSaveButtonFlag = false;

const EditPackage = ({
  packageData,
  setFetchDataInPackageDetail,
}: {
  packageData: PackageData;
  setFetchDataInPackageDetail: Dispatch<boolean>;
}): ReactElement => {
  const [writers, setWriters] = useState<string[]>([]);
  const [editors, setEditors] = useState<string[]>([]);
  const [writerManager, setWriterManager] = useState<string[]>([]);
  const [pointOfContact, setPointOfContact] = useState<string>('');

  const [callingEditApi, setCallingEditApi] = useState(false);
  const [apiResponse, setApiResponse] = useState<UpdatePackageResponse>();
  const [modalVisible, setModalVisible] = useState(false);

  const { token } = useAuthState();

  const isInheritedPOC = packageData.inheritedPointOfContact;

  // condition1 both package and service doesn't have a poc (nothing to delete)
  disableSaveButtonFlag1 =
    (packageData.pointOfContact === '' ||
      packageData.pointOfContact === undefined) &&
    (pointOfContact === undefined || pointOfContact === '');

  //condition2 service has a poc or not, but on editpackage page doesn't have a poc (customer trying to delete service poc on package page)
  disableSaveButtonFlag2 =
    (packageData.inheritedPointOfContact &&
      (pointOfContact === undefined || pointOfContact === '')) ??
    false;

  disableSaveButtonFlag = disableSaveButtonFlag1 || disableSaveButtonFlag2;
  console.log('respon', apiResponse);
  return (
    <>
      <Box margin={{ top: 'l', bottom: 'xxl' }}>
        <SpaceBetween size='l'>
          <Header variant='h1'>
            Edit package information for {packageData.packageName}
          </Header>
          <form onSubmit={(e) => e.preventDefault()}>
            <Form
              actions={
                <SpaceBetween direction='horizontal' size='xs'>
                  <Button href={window.location.hash.replace('/edit', '')}>
                    Cancel
                  </Button>
                  <Button
                    disabled={disableSaveButtonFlag}
                    onClick={() => {
                      if (!token) throw new NoTokenError();
                      setModalVisible(true);
                      setCallingEditApi(true);
                      void callApi(
                        new UpdatePackageCommand({
                          packageName: packageData.packageName,
                          updates: formPackageEditRequest(
                            packageData,
                            pointOfContact
                          ),
                        }),
                        token
                      ).then(setApiResponse);
                      setCallingEditApi(false);
                    }}
                    variant='primary'
                  >
                    Save changes
                  </Button>
                </SpaceBetween>
              }
            >
              <SpaceBetween direction='vertical' size='xs'>
                <EditOwners
                  isThisServicePage={false}
                  isInheritedPOC={isInheritedPOC ?? false}
                  writers={writers ?? packageData.writers}
                  setWriters={setWriters}
                  editors={editors ?? packageData.editors}
                  setEditors={setEditors}
                  writerManager={
                    writerManager ?? packageData.writerManager ?? []
                  }
                  setWriterManager={setWriterManager}
                  pointOfContact={packageData.pointOfContact}
                  setPointOfContact={setPointOfContact}
                />
              </SpaceBetween>
            </Form>
          </form>
        </SpaceBetween>

        <Modal
          onDismiss={() => {
            setModalVisible(false);
            setFetchDataInPackageDetail(true);
          }}
          visible={modalVisible}
          closeAriaLabel='Close modal'
          footer={
            <Box float='right'>
              <Button
                variant='primary'
                onClick={() => {
                  setFetchDataInPackageDetail(true);
                }}
                href={window.location.hash.replace('/edit', '')}
              >
                Package detail page
              </Button>
            </Box>
          }
          header={`Updating package information for ${packageData.packageName}`}
        >
          {callingEditApi && (
            <TextContent>
              <Spinner /> Updating package details
            </TextContent>
          )}
          {!callingEditApi ? (
            apiResponse?.status === 'SUCCESS' ? (
              <Box variant='p'>
                The request to update service details has completed.
              </Box>
            ) : (
              <Box color='text-status-error' variant='p'>
                There was an error updating this service.
              </Box>
            )
          ) : (
            ''
          )}
        </Modal>
      </Box>
    </>
  );
};

const formPackageEditRequest = (
  packageData: PackageData,
  pointOfContact?: string
): Record<string, string>[] => {
  const packageUpdates: PackageUpdates = {};

  if (pointOfContact !== packageData.pointOfContact) {
    packageUpdates.pointOfContact = pointOfContact;
  }

  return updatesToList(packageUpdates);
};

const updatesToList = (updates: PackageUpdates): Record<string, string>[] =>
  Object.entries(updates).map(([key, value]) =>
    Array.isArray(value)
      ? { [key]: JSON.stringify(value) }
      : { [key]: String(value) }
  );

export default EditPackage;
