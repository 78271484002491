import { createContext, ReactElement, useContext, useReducer } from 'react';
import { NodeChildren, Breadcrumb } from '../../interfaces';

const homepageBreadcrumb = { text: 'Virtual Smiley', href: '' };

const initialState = {
  breadcrumbTrail: [homepageBreadcrumb],
  breadcrumbNameOverrides: {
    '/services': 'Service Information',
    '/error': '',
  },
};

interface NavState {
  breadcrumbTrail: Breadcrumb[];
  breadcrumbNameOverrides: { [key: string]: string };
}

type NavAction =
  | { type: 'NAVIGATE_TO'; pathname: string }
  | { type: 'ADD_BREADCRUMB_OVERRIDE'; overrides: { [key: string]: string } };

const NavReducer = (initialState: NavState, action: NavAction): NavState => {
  switch (action.type) {
    case 'NAVIGATE_TO':
      return {
        ...initialState,
        ...{
          breadcrumbTrail: getBreadcrumbItems(
            action.pathname,
            initialState.breadcrumbNameOverrides
          ),
        },
      };
    case 'ADD_BREADCRUMB_OVERRIDE':
      return {
        ...initialState,
        breadcrumbNameOverrides: {
          ...initialState.breadcrumbNameOverrides,
          ...action.overrides,
        },
        breadcrumbTrail: updateBreadcrumbTrailWithNewOverrides(
          initialState.breadcrumbTrail,
          action.overrides
        ),
      };
  }
};

type Dispatch = (action: NavAction) => void;

const NavStateContext = createContext<NavState | undefined>(undefined);
const NavDispatchContext = createContext<Dispatch | undefined>(undefined);

/**
 * Returns the current nav state
 */
export const useNavState = (): NavState => {
  const context = useContext(NavStateContext);
  if (context === undefined) {
    throw new Error('useNavState must be used within a NavProvider');
  }

  return context;
};

/**
 * Returns a dispatcher to perform updates to the nav state
 */
export const useNavDispatch = (): Dispatch => {
  const context = useContext(NavDispatchContext);
  if (context === undefined) {
    throw new Error('useNavDispatch must be used within a NavProvider');
  }
  return context;
};

/**
 * Component for provider nav state and dispatcher
 * to child components
 */
export const NavProvider = ({ children }: NodeChildren): ReactElement => {
  const [data, dispatch] = useReducer(NavReducer, initialState);

  return (
    <NavStateContext.Provider value={data}>
      <NavDispatchContext.Provider value={dispatch}>
        {children}
      </NavDispatchContext.Provider>
    </NavStateContext.Provider>
  );
};

function updateBreadcrumbTrailWithNewOverrides(
  breadcrumbs: Breadcrumb[],
  newOverrides: {
    [x: string]: string;
  }
) {
  return breadcrumbs.map((breadcrumb): Breadcrumb => {
    return {
      text: newOverrides[breadcrumb.href.substring(1)] ?? breadcrumb.text,
      href: breadcrumb.href,
    };
  });
}

function getBreadcrumbItems(
  pathName: string,
  breadCrumbOverrides: { [key: string]: string }
): Breadcrumb[] {
  let urlPaths: string[] = ['/'];

  if (pathName !== '/') {
    // Path other than homepage
    urlPaths = pathName.split('/');
  }

  // First item in the breadcrumb is always the homepage
  const breadcrumbItems: Breadcrumb[] = [
    { text: 'Virtual Smiley', href: '#/' },
  ];

  let pathBuilder = '';

  // By default, breadcrumbs will be generated using the paths in the URL
  // A page can choose to override the displayed breadcrumb by adding an
  // entry in the breadCrumbOverrides map in NavStateContext
  // Overrides also exist in the initial nav state

  for (let i = 1; i < urlPaths.length; i++) {
    // Use breadcrumb name for the path if it exists
    const pageName = decodeURIComponent(urlPaths[i]);
    pathBuilder += '/' + urlPaths[i];

    if (breadCrumbOverrides[pathBuilder] !== undefined) {
      breadcrumbItems.push({
        text: breadCrumbOverrides[pathBuilder],
        href: '#' + pathBuilder,
      });
    } else {
      breadcrumbItems.push({
        text: pageName,
        href: '#' + pathBuilder,
      });
    }
  }
  return breadcrumbItems.length > 1 ? breadcrumbItems : []; //do not display breadcrumb on home page for now since there is just one item in breadcrumb
}
