import { PackageGuide } from '../../../interfaces';
import PackageGuides from './PackageGuides';
import {
  Box,
  Button,
  ColumnLayout,
  Container,
  Header,
  Link,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import ValueWithLabel from '../../ui/ValueWithLabel';
import { useLocation } from 'react-router';
import OwnershipTable from './OwnershipTable';
import { useAuthState } from '../../../authentication';
import { PACKAGE_STRINGS } from '../../../constants/UIStrings';
import { PackageData } from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import { ReactElement } from 'react';

const PackageDetailData = ({
  packageData,
}: {
  packageData: PackageData;
}): ReactElement => {
  const location = useLocation();
  const guides: PackageGuide[] = packageData.guides
    ? Object.values(JSON.parse(packageData.guides))
    : [];
  const guideComponents = guides.map((guide: PackageGuide) => {
    return (
      packageData.pathslug && (
        <PackageGuides
          guide={guide}
          key={guide.pathslug}
          packagePathSlug={packageData.pathslug}
        />
      )
    );
  });
  const { packageInfoPermissionsGroup } = useAuthState();

  return (
    <Box margin={{ top: 'l', bottom: 'xxl' }}>
      <SpaceBetween size='l'>
        <Header
          variant='h1'
          info={
            <Link
              external
              variant='info'
              href={`https://code.amazon.com/packages/${packageData.packageName}`}
            >
              View on code.amazon
            </Link>
          }
          actions={
            packageInfoPermissionsGroup &&
            packageInfoPermissionsGroup == 'info-editor' && (
              <Button href={`#${location.pathname}/edit`}>Edit</Button>
            )
          }
        >
          {packageData.packageName}
        </Header>
        <Container
          header={
            <Header
              variant='h2'
              description={PACKAGE_STRINGS['Naming conventions']}
            >
              Naming conventions
            </Header>
          }
        >
          <ColumnLayout columns={4} variant='text-grid'>
            <ValueWithLabel label='Service ID'>
              {packageData.serviceId ?? '-'}
            </ValueWithLabel>
            <ValueWithLabel label='Title'>{packageData.title}</ValueWithLabel>
            <ValueWithLabel label='Service pathslug'>
              {packageData.pathslug}
            </ValueWithLabel>
            <ValueWithLabel label='Feedback CTI'>
              {packageData.feedbackCTI ?? '-'}
            </ValueWithLabel>
            <ValueWithLabel label='Feedback SIM'>
              {packageData.feedbackSIM ?? '-'}
            </ValueWithLabel>
          </ColumnLayout>
        </Container>
        <Container header={<Header variant='h2'>TCX Proof of Concept</Header>}>
          <ColumnLayout columns={2} variant='text-grid'>
            <ValueWithLabel label='Quicksight Analytics'>
              {
                <Link
                  external
                  variant='info'
                  href={`https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/0ceab71d-4438-451b-8a1f-cf4a98e6c65b#p.Package=${packageData.packageName}&p.Service=${packageData.title}&p.Guide=All`}
                >
                  View package quicksight analytics
                </Link>
              }
            </ValueWithLabel>
          </ColumnLayout>
        </Container>
        <Header variant='h3'>Associated Guides</Header>
        {guideComponents}
        <Header variant='h3'>More package info</Header>
        <OwnershipTable
          writers={packageData.writers ?? []}
          writerMangers={packageData.writerManager ?? []}
          editors={packageData.editors}
          pointOfContact={packageData.pointOfContact}
          isInheritedPointOfContact={
            packageData.inheritedPointOfContact ?? false
          }
          isThisServicePage={false}
        />
        {/*<PackageOptionsTable*/}
        {/*  forumID={packageData.forumId}*/}
        {/*  consoleHelp={packageData.options?.consoleHelp}*/}
        {/*  regionSpecificImages={packageData.options?.regionSpecificImages}*/}
        {/*/>*/}
      </SpaceBetween>
    </Box>
  );
};

export default PackageDetailData;
