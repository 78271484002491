// Promise polyfill
import 'core-js/features/promise';

import { render } from 'react-dom';
import { HashRouter } from 'react-router-dom';
import App from './components/App';
import '@amzn/awsui-global-styles/polaris.css';
import { NavProvider } from './components/navigation/context';
import { AuthProvider } from './authentication';

document.addEventListener('DOMContentLoaded', () => {
  render(
    <HashRouter>
      <NavProvider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </NavProvider>
    </HashRouter>,
    document.querySelector('#app')
  );
});
