import styles from './List.module.sass';
import { Tabs } from '@amzn/awsui-components-react';
import { OfferingList } from '../../OfferingList';
import { OfferingReviewList } from '../../OfferingReviewList';
import { ReactElement } from 'react';

export const List = (): ReactElement => (
  <Tabs
    className={styles.List}
    variant='container'
    tabs={[
      {
        label: 'Offerings',
        id: 'offerings',
        content: <OfferingList />,
        href: '#/Moniker/offerings',
      },
      {
        label: 'Offering drafts',
        id: 'drafts',
        content: <OfferingReviewList />,
        href: '#/Moniker/drafts',
      },
    ]}
    activeTabId={/Moniker\/(drafts|offerings)\/?$/.exec(location.hash)?.[1]}
    onChange={(e) => (location.hash = e.detail.activeTabHref ?? '/Moniker')}
  />
);
