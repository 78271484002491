import { createContext, ReactElement, useContext, useReducer } from 'react';
import { NodeChildren, PermissionsGroups } from '../interfaces';

interface AuthState {
  isLoading?: boolean;
  isAuthenticated: boolean;
  userAlias?: string;
  token?: string;
  userGroups?: string[];
  error?: Record<string, unknown>;
  packageInfoPermissionsGroup?: PermissionsGroups;
}

const initialState: AuthState = {
  isLoading: true,
  isAuthenticated: false,
  userGroups: [],
};

interface AuthAction {
  type: 'LOGIN';
  payload: AuthState;
}

const AuthReducer = (
  initialState: AuthState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...initialState,
        ...action.payload,
      };
  }
};

type Dispatch = (action: AuthAction) => void;

const AuthStateContext = createContext<AuthState | undefined>(undefined);
const AuthDispatchContext = createContext<Dispatch | undefined>(undefined);

/**
 * Returns the current authentication state
 */
export function useAuthState(): AuthState {
  const context = useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider');
  }

  return context;
}

/**
 * Returns a dispatcher to perform updates to the authentication state
 */
export function useAuthDispatch(): Dispatch {
  const context = useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthProvider');
  }

  return context;
}

/**
 * Component for provider authentication state and dispatcher
 * to child components
 */
export const AuthProvider = ({ children }: NodeChildren): ReactElement => {
  const [user, dispatch] = useReducer(AuthReducer, initialState);

  return (
    <AuthStateContext.Provider value={user}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};
