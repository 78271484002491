import { RegionalNameInformationInText } from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import { FormField } from '@amzn/awsui-components-react';
import { ReactElement } from 'react';
import { RegionMapEditor } from '../../RegionMapEditor';
import { FieldProps } from '../FieldProps';

export const LongNameField = (
  props: FieldProps<RegionalNameInformationInText | undefined, true>
): ReactElement => (
  <FormField label='Long name'>
    {props.old && props.old !== props.value && (
      <RegionMapEditor {...props} value={props.old} />
    )}
    <RegionMapEditor {...props} />
  </FormField>
);
