import React, { ReactElement } from 'react';
import { Header, Table, Box } from '@amzn/awsui-components-react/polaris';
import { Github } from '../../../interfaces';

interface GitHubInfoTableProps {
  github: Github | undefined;
}

function GitHubInfoTable({ github }: GitHubInfoTableProps): ReactElement {
  const items = [];

  if (github) {
    if (github.branch) items.push({ key: 'Branch', value: github.branch });

    if (github.repo) items.push({ key: 'Repo', value: github.repo });

    if (github.sourceFolder)
      items.push({ key: 'Source-folder', value: github.sourceFolder });

    if (github.user) items.push({ key: 'User', value: github.user });
  }

  return (
    <Table
      columnDefinitions={[
        {
          id: 'key',
          header: 'Key',
          cell: (item) => item.key ?? '-',
        },
        {
          id: 'value',
          header: 'Value / link',
          cell: (item) => item.value ?? '-',
        },
      ]}
      items={items}
      loadingText='Loading builds'
      empty={
        <Box textAlign='center' color='inherit'>
          <b>No GitHub information</b>
          <Box padding={{ bottom: 's' }} variant='p' color='inherit'>
            No GitHub information to display.
          </Box>
        </Box>
      }
      header={<Header counter={`(${items.length})`}>GitHub Info</Header>}
    />
  );
}

export default GitHubInfoTable;
