interface Endpoint {
  // the client ID from Cognito "General settings > Moniker clients" page
  devClientId: string;
  // the user pool from Cognito "General settings" page
  devEndpointUserPoolId: string;
  appDomain: string;
}

export const alphaEndpoint: Endpoint = {
  devClientId: '74e1ie3pou31vt1busld90ntu9',
  devEndpointUserPoolId: 'us-east-1_wqk094Ssj',
  appDomain: 'alpha-virtualsmiley.auth.us-east-1.amazoncognito.com',
};

export const betaEndpoint: Endpoint = {
  devClientId: '425bsuoiqsieq3mr47ftg2htu1',
  devEndpointUserPoolId: 'us-east-1_V9miRI0Bq',
  appDomain: 'beta-virtualsmiley.auth.us-east-1.amazoncognito.com',
};

export const gammaEndpoint: Endpoint = {
  devClientId: '25sas9f9bbjpsa3msll7dc4f5n',
  devEndpointUserPoolId: 'us-east-1_KeD4WP1wZ',
  appDomain: 'gamma-virtualsmiley.auth.us-east-1.amazoncognito.com',
};

export const prodEndpoint: Endpoint = {
  devClientId: '69tsd8gq2g1sa0tehoi4ceoare',
  devEndpointUserPoolId: 'us-east-1_CH3HGUYQw',
  appDomain: 'virtualsmiley.auth.us-east-1.amazoncognito.com',
};

export const devEndpoint: Endpoint = {
  devClientId: '6j5dm4qa9t3i8lvih2fmd4fnbo',
  devEndpointUserPoolId: 'us-east-1_DjIaM0kxo',
  appDomain: 'dev-virtualsmiley.auth.us-east-1.amazoncognito.com',
};
