import { OwnerRole } from '../../../interfaces';

export function getOwnerKey(ownerEmail: string, ownerRole: OwnerRole): string {
  return `${ownerEmail.replace(/\s+/g, '')}${ownerRole.replace(/\s+/g, '')}`;
}

export function stringIncludesSpace(string: string | undefined): boolean {
  if (string) {
    return string.trim().includes(' ');
  } else {
    return false;
  }
}

export function stringExistsInArray(string: string, array: string[]): boolean {
  return array.includes(string.trim());
}

export function arrayContentsEqual(
  array1: string[],
  array2: string[]
): boolean {
  if (array1.length === array2.length) {
    return array1.every((element) => {
      if (array2.includes(element)) {
        return true;
      }
      return false;
    });
  }

  return false;
}
