import { useState, useEffect, ReactElement } from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Box,
  Button,
  Pagination,
  Header,
  Table,
  TextFilter,
  Select,
} from '@amzn/awsui-components-react/polaris';
import { EmptyState } from '../../ui/EmptyState';
import {
  getServiceLink,
  getListOfPackageLinks,
  getListOfPathSlugs,
  getFeedbackFolderLink,
  getKeyValuePairsAsList,
  getServiceOwners,
  truncateArray,
} from '../utils';
import { FetchedServiceData } from '../../../interfaces';
import CollectionPreferences from '@amzn/awsui-components-react/polaris/collection-preferences';
import styles from './ServicesTable.module.scss';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { LeadershipChainList } from '../../../components/LeadershipChainList';
import {
  AwsService,
  Employee,
  ServiceContact,
  ExportServicesCommand,
  ExportServicesCommandOutput,
} from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import { callApi } from '../../../client';
import { useAuthState } from '../../../authentication';

const ServicesTable = ({ data, loading }: FetchedServiceData): ReactElement => {
  const { token } = useAuthState();
  const SERVICES_COLUMNS = 'servicesColumns';
  const VISIBLE_COLUMNS = [
    'serviceDisplayName',
    'serviceId',
    'pathslug',
    'leadershipChain',
    'owners',
    'docPackages',
    'feedbackCTI',
    'serviceContact',
    'isFunded',
  ];
  //const ownerFields = ['writers', 'editors', 'writerManager', 'pointOfContact'];
  const ownerFields: (keyof AwsService)[] = [
    'writers',
    'editors',
    'writerManager',
    'pointOfContact',
  ];
  const [preferences, setPreferences] = useState({
    pageSize: 20,
    wrapLines: true,
    visibleContent: VISIBLE_COLUMNS,
  });

  const packageFilterOptions: OptionDefinition[] = [
    { value: '1', label: 'All services' },
    { value: '0', label: 'Services w/packages' },
  ];
  const [selectedPackageFilterOption, setSelectedPackageFilterOption] =
    useState(packageFilterOptions[1]);

  const servicesColumns = [
    {
      id: 'serviceDisplayName',
      header: 'Service display name',
      cell: (s: AwsService) =>
        getServiceLink(s.serviceDisplayName, s.serviceId),
      sortingField: 'serviceDisplayName',
    },
    {
      id: 'owners',
      header: 'Owners',
      cell: (s: AwsService) =>
        getServiceOwners({
          serviceId: s.serviceId,
          pointOfContact: s.pointOfContact || '',
          writers: s.writers || [],
          editors: s.editors || [],
          writerManager: s.writerManager || [],
          className: styles.nonBulletedList,
        }),
      sortingField: 'owners',
    },
    {
      id: 'serviceId',
      header: 'Service ID',
      cell: (s: AwsService) => s.serviceId || '-',
      sortingField: 'serviceId',
      maxWidth: 200,
    },
    {
      id: 'pathslug',
      header: 'Pathslugs',
      cell: (s: AwsService) => s.pathslug && getListOfPathSlugs(s.pathslug),
      sortingField: 'pathslug',
    },
    {
      id: 'docPackages',
      header: 'Packages',
      cell: (s: AwsService) =>
        truncateArray(getListOfPackageLinks(s.serviceId, s.docPackages ?? [])),
      sortingField: 'docPackages',
    },
    {
      id: 'leadershipChain',
      header: 'Leadership Chain',
      cell: (s: AwsService) =>
        s.serviceId?.startsWith('temp_id') ? (
          'N/A'
        ) : (
          <LeadershipChainList chain={s.serviceContact?.leadershipChain} />
        ),
      sortingField: 'leadershipChain',
    },
    {
      id: 'feedbackCTI',
      header: 'CTI',
      cell: (s: AwsService) =>
        truncateArray(getFeedbackFolderLink(s.feedbackCTI ?? [])),
      sortingField: 'feedbackCTI',
    },
    {
      id: 'regionStatus',
      header: 'Region status',
      cell: (s: AwsService) =>
        getKeyValuePairsAsList(s.regionStatus, styles.regionStatusString),
      sortingField: 'regionStatus',
      minWidth: 250,
    },
    {
      id: 'isFunded',
      header: 'Funded',
      cell: (s: AwsService) => {
        console.log(s, 'funded?', s.isFunded);
        return s.isFunded ? 'Yes' : 'No';
      },
      sortingField: 'isFunded',
    },
  ];

  // Set initial visible columns from localStorage if it exists
  useEffect(() => {
    const columns = localStorage.getItem(SERVICES_COLUMNS);
    if (columns) {
      const savedVisibleCols = columns.split(',');
      setPreferences({ ...preferences, visibleContent: savedVisibleCols });
    }
  }, []);

  // Save column preferences to localStorage
  useEffect(() => {
    const allColumnsVisible = servicesColumns.every(({ id }) =>
      preferences.visibleContent.includes(id)
    );

    if (allColumnsVisible) {
      localStorage.removeItem(SERVICES_COLUMNS);
    } else {
      localStorage.setItem(
        SERVICES_COLUMNS,
        preferences.visibleContent.toString()
      );
    }
  }, [preferences.visibleContent]);

  const hideEmployee = (employeeLvl: number) => {
    return employeeLvl < 7 || employeeLvl > 11;
  };

  const textMatch = (inputText: string, verifyingText: string) => {
    return inputText.toLowerCase().includes(verifyingText.toLowerCase());
  };

  const leadershipChainMatches = (
    value: ServiceContact,
    filteringText: string
  ): boolean =>
    value.leadershipChain?.some((employee: Employee) => {
      //hide employee lvl < 7 and lvl > 11
      const search_item = `L${employee.level} ${employee.firstName ?? ''} ${
        employee.lastName ?? ''
      } ${employee.alias}`;

      return (
        !hideEmployee(employee.level) && textMatch(search_item, filteringText)
      );
    }) ?? false;

  const isArrayMatches = (value: string[], filteringText: string) => {
    return value.length && value.some((s) => textMatch(s, filteringText));
  };

  const isLeadershipChainMatches = (
    value: ServiceContact,
    filteringText: string
  ) => {
    return (
      value.leadershipChain && leadershipChainMatches(value, filteringText)
    );
  };

  //  The useCollection React hook takes original items and a configuration and returns filtered, sorted and paginated content, according to your configuration.
  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(data || [], {
    filtering: {
      empty: (
        // TODO: Update this empty state to use an Alert component
        <EmptyState
          title='The list of services could not be loaded'
          subtitle='Try refreshing your browser. If that doesn’t work, our team is most likely working on resolving it.'
          action={
            <Button onClick={() => location.reload()}>Refresh browser </Button>
          }
        />
      ),
      noMatch: (
        <EmptyState
          title='No matches'
          subtitle='No services matched the specified filter'
          action={
            <Button onClick={() => actions.setFiltering('')}>
              Clear filter
            </Button>
          }
        />
      ),
      filteringFunction: (serviceData, filteringText) => {
        if (!serviceData) {
          return false;
        }
        // filter out services when doesn't have a packages and user choose display service with packages
        if (
          selectedPackageFilterOption.value === '0' &&
          !serviceData.docPackages?.length
        ) {
          return false;
        }

        return [...VISIBLE_COLUMNS, ...ownerFields]
          .filter((key: string) => key in serviceData)
          .map((key: string) => serviceData[key as keyof typeof serviceData])
          .some((value) => {
            switch (typeof value) {
              case 'string':
                return textMatch(value, filteringText);
              case 'object':
                return Array.isArray(value)
                  ? isArrayMatches(value, filteringText)
                  : isLeadershipChainMatches(value, filteringText);
              default:
                return false;
            }
          });
      },
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {
      defaultState: { sortingColumn: servicesColumns[0], isDescending: false },
    },
    selection: {},
  });

  const downloadCsv = (filename: string, text: string) => {
    const dataStr = 'data:text/csv;charset=utf-8,' + encodeURIComponent(text);
    const node = document.createElement('a');
    node.setAttribute('href', dataStr);
    node.setAttribute('download', filename + '.csv');
    document.body.appendChild(node);
    node.click();
    node.remove();
  };
  const exportAll = async () => {
    if (token) {
      try {
        let response: ExportServicesCommandOutput = await callApi(
          new ExportServicesCommand({
            includeServicesWithoutPackages:
              selectedPackageFilterOption.value === '1',
          }),
          token
        );

        if (response.$metadata.httpStatusCode == 200) {
          const text = new TextDecoder().decode(response.output);
          downloadCsv(`TLC-exportServices-${+new Date()}`, text);
        } else {
          console.error(
            'Unexpected response status code:',
            response.$metadata.httpStatusCode
          );
        }
      } catch (err: any) {
        console.log('Error with ExportServices API', err);
      }
    } else {
      alert("You're not logged in");
    }
  };

  return (
    <Box margin={{ top: 'l', bottom: 'xxl' }}>
      <Table
        {...collectionProps}
        header={
          <Header
            counter={
              data && filteredItemsCount
                ? `(${filteredItemsCount}/${data.length})`
                : `(${items.length})`
            }
            actions={
              <Button variant='primary' onClick={exportAll}>
                Export All
              </Button>
            }
          >
            AWS Service Information
          </Header>
        }
        trackBy='serviceId'
        loading={loading}
        loadingText='Retrieving service data'
        columnDefinitions={servicesColumns}
        visibleColumns={preferences.visibleContent}
        items={items}
        pagination={<Pagination {...paginationProps} />}
        wrapLines
        filter={
          <div className={styles.inputContainer}>
            <div className={styles.inputFilter}>
              <TextFilter
                {...filterProps}
                filteringPlaceholder='Filter by service name, category, CTI, and more '
              />
            </div>
            <div className={styles.selectFilter}>
              <Select
                options={packageFilterOptions}
                selectedAriaLabel='Selected'
                selectedOption={selectedPackageFilterOption}
                onChange={(event) => {
                  setSelectedPackageFilterOption(event.detail.selectedOption);
                }}
                ariaDescribedby={'filter'}
              />
            </div>
          </div>
        }
        preferences={
          <CollectionPreferences
            onConfirm={({ detail }) =>
              setPreferences({
                ...preferences,
                pageSize: detail.pageSize ?? 10,
                visibleContent: detail.visibleContent as string[],
              })
            }
            preferences={preferences}
            visibleContentPreference={{
              title: 'Select visible content',
              options: [
                {
                  label: 'Columns: ',
                  options: [
                    { id: 'serviceDisplayName', label: 'Service display name' },
                    { id: 'serviceId', label: 'Service ID' },
                    { id: 'pathslug', label: 'Pathslug' },
                    { id: 'docPackages', label: 'Packages' },
                    { id: 'leadershipChain', label: 'Leadership Chain' },
                    { id: 'owners', label: 'Owners' },
                    { id: 'feedbackCTI', label: 'CTI' },
                    { id: 'regionStatus', label: 'Region status' },
                    { id: 'isFunded', label: 'Funded' },
                  ],
                },
              ],
            }}
            pageSizePreference={{
              title: 'Select page size',
              options: [
                { value: 20, label: '20 services' },
                { value: 50, label: '50 services' },
              ],
            }}
            cancelLabel='Cancel'
            confirmLabel='Confirm'
            title='Preferences'
          />
        }
      />
    </Box>
  );
};

export default ServicesTable;
