import { FetchedServiceDetailData, ServiceUpdates } from '../../interfaces';
import deepEqual from 'deep-equal';
import {
  Autosuggest,
  AutosuggestProps,
  Box,
  Container,
  Form,
  FormField,
  Grid,
  Header,
  Input,
  Modal,
  Select,
  SpaceBetween,
  Spinner,
  TextContent,
} from '@amzn/awsui-components-react';
import { useParams } from 'react-router-dom';
import { Button } from '@amzn/awsui-components-react/polaris';
import { ReactElement, useEffect, useState } from 'react';
import InputWrapper from './ui/InputWrapper';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import {
  ErrorRefreshPage,
  ErrorServiceNotFoundInRetrievedData,
} from '../ui/ErrorLoadingPage';
import { useAuthState } from '../../authentication';
import {
  arrayContentsEqual,
  stringExistsInArray,
  stringIncludesSpace,
} from './editComponents/utils';
import EditOwners from './editComponents/EditOwners';
import { AutoSuggestStatus } from '../../types/AutoSuggestStatus';
import { SERVICE_STRINGS } from '../../constants/UIStrings';
import {
  AwsService,
  ListPackagesCommand,
  UpdateServiceCommand,
} from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import { callApi } from '../../client';

const fundedOption = { label: 'Funded', value: 'Funded' };
const unfundedOption = { label: 'Not funded', value: 'Not funded' };
const fundingOptions = [fundedOption, unfundedOption];
const EditService = ({
  serviceData,
  serviceDataNotFound,
  loading,
  setFetchDataInServiceList,
}: FetchedServiceDetailData): ReactElement => {
  const { serviceId } = useParams<{ serviceId: string }>();
  const { token } = useAuthState();

  const [displayName, setDisplayName] = useState(
    serviceData?.serviceDisplayName ?? ''
  );
  useEffect(
    () => setDisplayName(serviceData?.serviceDisplayName ?? ''),
    [serviceData?.serviceDisplayName]
  );
  const [displayNameErrorText, setDisplayNameErrorText] = useState('');

  const [callingEditApi, setCallingEditApi] = useState(false);
  const [apiResponse, setApiResponse] = useState('');
  const [modalVisible, setModalVisible] = useState(false);

  const [restrictedStatus, setRestrictedStatus] = useState<OptionDefinition>();

  const [fetchAvailablePackages, setFetchAvailablePackages] = useState(true);
  const [availablePackagesList, setAvailablePackagesList] = useState<string[]>(
    []
  );
  const [availablePackagesOptions, setAvailablePackagesOptions] =
    useState<AutosuggestProps.Options>([]);
  const [availablePackagesStatus, setAvailablePackagesStatus] =
    useState<AutoSuggestStatus>('finished');

  const [packageNames, setPackageNames] = useState<string[]>([]);
  const [newPackageNames, setNewPackageNames] = useState<string[]>([]);
  const [packageNameToAdd, setPackageNameToAdd] = useState<string>('');
  const packageInputFields = packageNames.map((pkg) => {
    return (
      <InputWrapper
        inputValue={pkg}
        removable={true}
        stringArray={packageNames}
        updateStringArray={setPackageNames}
        key={pkg}
      />
    );
  });
  const newPackageInputFields = newPackageNames.map((pkg) => {
    return (
      <InputWrapper
        inputValue={pkg}
        removable={true}
        stringArray={newPackageNames}
        updateStringArray={setNewPackageNames}
        key={pkg}
      />
    );
  });

  const [pathslugs, setPathslugs] = useState<string[]>([]);

  const pathslugInputFields = pathslugs.map((pathslug) => {
    return (
      <InputWrapper
        inputValue={pathslug}
        removable={false}
        stringArray={pathslugs}
        updateStringArray={setPathslugs}
        key={pathslug}
      />
    );
  });

  const [newPathslugs, setNewPathslugs] = useState<string[]>([]);
  const [pathslugToAdd, setPathslugToAdd] = useState('');
  const [pathslugErrorText, setPathSlugErrorText] = useState('');
  const newPathslugInputFields = newPathslugs.map((newPathslug) => {
    return (
      <InputWrapper
        inputValue={newPathslug}
        removable={true}
        stringArray={newPathslugs}
        updateStringArray={setNewPathslugs}
        key={newPathslug}
      />
    );
  });

  const [writers, setWriters] = useState<string[]>([]);
  const [editors, setEditors] = useState<string[]>([]);
  const [writerManager, setWriterManager] = useState<string[]>([]);
  const [pointOfContact, setPointOfContact] = useState('');
  const [isFunded, setIsFunded] = useState<boolean>(
    serviceData?.isFunded ?? false
  );

  useEffect(() => {
    async function getAvailablePackages() {
      if (token && fetchAvailablePackages) {
        try {
          setAvailablePackagesStatus('loading');
          const availablePackages = await callPackageListApi(token);
          setAvailablePackagesList(availablePackages);
          setAvailablePackagesStatus('finished');
        } catch (err) {
          setAvailablePackagesStatus('error');
          console.log('error with FETCH', err);
        }
        setFetchAvailablePackages(false);
      }
    }
    void getAvailablePackages();
  }, [fetchAvailablePackages]);

  useEffect(() => {
    if (serviceData) {
      setPackageNames(serviceData?.docPackages ?? []);

      setPathslugs(serviceData.pathslug ?? []);

      if (serviceData.restrictedStatus) {
        setRestrictedStatus({
          label: serviceData.restrictedStatus,
          value: serviceData.restrictedStatus,
        });
      }
    }
  }, [serviceData]);

  useEffect(() => {
    if (stringIncludesSpace(pathslugToAdd)) {
      setPathSlugErrorText('Pathslugs cannot include spaces');
    } else if (
      stringExistsInArray(pathslugToAdd, [...pathslugs, ...newPathslugs])
    ) {
      setPathSlugErrorText('Cannot have duplicate pathslugs');
    } else {
      setPathSlugErrorText('');
    }
  }, [pathslugToAdd]);

  useEffect(() => {
    if (availablePackagesList.length > 0) {
      const filteredPackageNames = availablePackagesList?.filter((pkg) => {
        return ![...newPackageNames, ...packageNames].includes(pkg);
      });
      const options: AutosuggestProps.Options =
        filteredPackageNames?.length > 0
          ? filteredPackageNames.sort().map((value) => ({ value }))
          : [];
      setAvailablePackagesOptions(options);
    }
  }, [newPackageNames, packageNames, availablePackagesList]);

  // Still retrieving data
  if (loading) {
    return <Spinner size='large' />;
  }

  // Data was not retrieved
  if (!serviceData) {
    return <ErrorRefreshPage />;
  }

  // Data retrieved but requested service not found
  if (serviceDataNotFound) {
    return <ErrorServiceNotFoundInRetrievedData />;
  }
  const [submittable, setSubmittable] = useState(false);
  const buildEditRequest = () =>
    formEditRequest(
      displayName,
      writers,
      editors,
      writerManager,
      pointOfContact,
      [...pathslugs, ...newPathslugs],
      restrictedStatus?.value,
      [...newPackageNames, ...packageNames],
      serviceData,
      isFunded
    );
  useEffect(
    () => setSubmittable(Object.keys(buildEditRequest()).length > 0),
    [
      displayName,
      writers,
      editors,
      writerManager,
      pointOfContact,
      pathslugs,
      newPathslugs,
      restrictedStatus?.value,
      newPackageNames,
      packageNames,
      serviceData,
      isFunded,
    ]
  );

  if (serviceData && !serviceDataNotFound) {
    return (
      <>
        <Box margin={{ top: 'l', bottom: 'xxl' }}>
          <SpaceBetween size='l'>
            <Header variant='h1'>
              Edit service information for{' '}
              {serviceData?.serviceDisplayName || serviceId}{' '}
            </Header>

            <form onSubmit={(e) => e.preventDefault()}>
              <Form
                actions={
                  <SpaceBetween direction='horizontal' size='xs'>
                    <Button href={`#/services/${serviceId}`}>Cancel</Button>
                    <Button
                      disabled={!submittable}
                      onClick={async () => {
                        setModalVisible(true);
                        setCallingEditApi(true);
                        const requestBody = buildEditRequest();
                        const response = await callServiceEditApi(
                          serviceData?.serviceId,
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          requestBody,
                          token
                        );
                        setCallingEditApi(false);

                        // Clear/reset the fields
                        setNewPackageNames([]);
                        setNewPathslugs([]);
                        setPackageNameToAdd('');
                        setPathslugToAdd('');
                        setWriters([]);
                        setEditors([]);
                        setIsFunded(false);
                        setWriterManager([]);
                        setPointOfContact('');
                        setApiResponse(response);
                        setAvailablePackagesOptions([]);
                        setAvailablePackagesList([]);
                        setFetchAvailablePackages(true);
                      }}
                      variant='primary'
                    >
                      Save changes
                    </Button>
                  </SpaceBetween>
                }
              >
                <SpaceBetween size='m'>
                  <Container
                    header={
                      <Header
                        variant='h2'
                        description={'Like an ID but better!'}
                      >
                        Display name
                      </Header>
                    }
                  >
                    <SpaceBetween direction='vertical' size='xxl'>
                      <Box>
                        <SpaceBetween direction='vertical' size='xs'>
                          <Grid
                            disableGutters
                            gridDefinition={[{ colspan: 9 }]}
                          >
                            <FormField
                              errorText={displayNameErrorText}
                              label='Set a new display name'
                            >
                              <Input
                                onChange={({ detail }) =>
                                  setDisplayName(detail.value)
                                }
                                value={displayName}
                                placeholder='Enter new display name'
                              />
                            </FormField>
                          </Grid>
                        </SpaceBetween>
                      </Box>
                    </SpaceBetween>
                  </Container>
                  <Container
                    header={
                      <Header
                        variant='h2'
                        description={
                          'Restricted Status determines who can see the service. Restricted services will be limited to members of the Technical Content organization and can be leveraged for hiding pre-release services. Unrestricted services will be viewable by anyone with access to Virtual Smiley.'
                        }
                      >
                        Restriction Status
                      </Header>
                    }
                  >
                    {' '}
                    <FormField label='Restriction status'>
                      <Select
                        placeholder={'Choose an option'}
                        selectedOption={restrictedStatus || null}
                        onChange={({ detail }) =>
                          setRestrictedStatus(detail.selectedOption)
                        }
                        options={[
                          { label: '-', value: '' },
                          { label: 'Restricted', value: 'Restricted' },
                          { label: 'Unrestricted', value: 'Unrestricted' },
                        ]}
                        selectedAriaLabel='Selected'
                      />
                    </FormField>
                  </Container>
                  <Container
                    header={
                      <Header
                        variant='h2'
                        description={SERVICE_STRINGS.fundingStatus}
                      >
                        Funding status
                      </Header>
                    }
                  >
                    {' '}
                    <FormField label='Funding status'>
                      <Select
                        placeholder={'Choose an option'}
                        selectedOption={
                          isFunded ? fundedOption : unfundedOption
                        }
                        onChange={({ detail }) =>
                          setIsFunded(
                            detail.selectedOption.value === fundedOption.value
                          )
                        }
                        options={fundingOptions}
                        selectedAriaLabel='Selected'
                      />
                    </FormField>
                  </Container>

                  <Container
                    header={
                      <Header
                        variant='h2'
                        description={
                          'Pathslugs contained in the build-info.xml file of a related package will be automatically included. These pathslugs can only be disassociated from the service by removing the package.'
                        }
                      >
                        Pathslugs ({pathslugs.length + newPathslugs.length})
                      </Header>
                    }
                  >
                    {' '}
                    <SpaceBetween direction='vertical' size='xxl'>
                      {pathslugs.length + newPathslugs.length > 0 && (
                        <SpaceBetween direction='vertical' size='xs'>
                          {pathslugInputFields}
                          {newPathslugInputFields}
                        </SpaceBetween>
                      )}
                      <Box>
                        <SpaceBetween direction='vertical' size='xs'>
                          <Grid
                            disableGutters
                            gridDefinition={[{ colspan: 9 }]}
                          >
                            <FormField
                              errorText={pathslugErrorText}
                              label='Add a new pathslug'
                            >
                              <Input
                                onChange={({ detail }) =>
                                  setPathslugToAdd(detail.value)
                                }
                                value={pathslugToAdd}
                                placeholder='Enter new pathslug'
                              />
                            </FormField>
                          </Grid>
                          <Button
                            disabled={
                              pathslugToAdd === '' ||
                              stringIncludesSpace(pathslugToAdd) ||
                              pathslugErrorText !== ''
                            }
                            onClick={() => {
                              if (
                                pathslugToAdd.trim() &&
                                !stringIncludesSpace(pathslugToAdd)
                              ) {
                                setNewPathslugs([
                                  ...newPathslugs,
                                  pathslugToAdd.trim(),
                                ]);
                                setPathslugToAdd('');
                              }
                            }}
                          >
                            Add pathslug
                          </Button>
                        </SpaceBetween>
                      </Box>
                    </SpaceBetween>
                  </Container>

                  <Container
                    header={
                      <Header
                        variant='h2'
                        description='Information such as pathslug, ownership, and feedback location will be automatically added/removed when the request is processed.'
                      >
                        Related packages (
                        {packageNames.length + newPackageNames.length})
                      </Header>
                    }
                  >
                    <SpaceBetween direction='vertical' size='xxl'>
                      {packageInputFields.length +
                        newPackageInputFields.length >
                        0 && (
                        <SpaceBetween direction='vertical' size='xs'>
                          {packageInputFields}
                          {newPackageInputFields}
                        </SpaceBetween>
                      )}
                      <Box>
                        <TextContent>
                          <p>
                            Find a package to add
                            <br />
                            <small>
                              A corresponding pathslug will be added to the
                              service details when the request for a new package
                              is submitted.
                            </small>
                          </p>
                        </TextContent>
                        <SpaceBetween direction='vertical' size='xs'>
                          <Grid
                            disableGutters
                            gridDefinition={[{ colspan: 9 }]}
                          >
                            <Autosuggest
                              onChange={({ detail }) =>
                                setPackageNameToAdd(detail.value)
                              }
                              value={packageNameToAdd}
                              options={availablePackagesOptions}
                              enteredTextLabel={(value) => `Use: "${value}"`}
                              ariaLabel='Autosuggest for packages to add to service'
                              placeholder='Search using package name'
                              empty='No matches found'
                              loadingText='Loading packages'
                              statusType={availablePackagesStatus}
                              errorText={'Error retrieving packages.'}
                              recoveryText={'Retry'}
                              onLoadItems={({ detail: { samePage } }) => {
                                if (
                                  availablePackagesStatus === 'error' &&
                                  samePage
                                ) {
                                  setFetchAvailablePackages(true);
                                }
                              }}
                            />
                          </Grid>
                          <Button
                            disabled={packageNameToAdd === ''}
                            onClick={() => {
                              if (packageNameToAdd.trim()) {
                                setNewPackageNames([
                                  ...newPackageNames,
                                  packageNameToAdd.trim(),
                                ]);
                                setPackageNameToAdd('');
                              }
                            }}
                          >
                            Add package
                          </Button>
                        </SpaceBetween>
                      </Box>
                    </SpaceBetween>
                  </Container>

                  <EditOwners
                    isThisServicePage={true}
                    isInheritedPOC={true}
                    writers={serviceData.writers ?? []}
                    setWriters={setWriters}
                    editors={serviceData.editors}
                    setEditors={setEditors}
                    writerManager={serviceData.writerManager ?? []}
                    setWriterManager={setWriterManager}
                    pointOfContact={serviceData.pointOfContact}
                    setPointOfContact={setPointOfContact}
                  />
                </SpaceBetween>
              </Form>
            </form>
          </SpaceBetween>
        </Box>
        <Modal
          onDismiss={() => {
            setModalVisible(false);
            setFetchDataInServiceList?.(true);
          }}
          visible={modalVisible}
          closeAriaLabel='Close modal'
          footer={
            <Box float='right'>
              <SpaceBetween direction='horizontal' size='xs'>
                <Button variant='link' href='#/services'>
                  Services list view
                </Button>
                <Button
                  variant='primary'
                  onClick={() => {
                    setFetchDataInServiceList?.(true);
                  }}
                  href={`#/services/${serviceId}`}
                >
                  Service detail page
                </Button>
              </SpaceBetween>
            </Box>
          }
          header={`Updating service information for ${
            serviceData.serviceDisplayName || serviceId
          }`}
        >
          {callingEditApi && (
            <TextContent>
              <Spinner /> Updating service details
            </TextContent>
          )}
          {!callingEditApi ? (
            apiResponse === '200' ? (
              <Box variant='p'>
                The request to update service details has completed.
              </Box>
            ) : (
              <Box color='text-status-error' variant='p'>
                There was an error updating this service.
              </Box>
            )
          ) : (
            ''
          )}
        </Modal>
      </>
    );
  }

  return <ErrorRefreshPage />;
};

function formEditRequest(
  serviceDisplayName: string,
  writers: string[],
  editors: string[],
  writerManager: string[],
  pointOfContact: string,
  pathSlugs: string[],
  restrictedStatus: string | undefined,
  docPackages: string[],
  serviceData?: AwsService,
  isFunded?: boolean
): Record<keyof AwsService, string> {
  const serviceUpdates: Record<string, any> = {};

  if (serviceData) {
    if (
      typeof isFunded === 'boolean' &&
      isFunded !== (serviceData.isFunded ?? false)
    )
      serviceUpdates.isFunded = String(isFunded);
    if (serviceData.serviceDisplayName !== serviceDisplayName)
      serviceUpdates.serviceDisplayName = serviceDisplayName;
    if (!arrayContentsEqual(writers, serviceData.writers ?? [])) {
      serviceUpdates.writers = writers;
    }
    if (
      serviceData.editors &&
      !arrayContentsEqual(editors, serviceData.editors)
    ) {
      serviceUpdates.editors = editors;
    }
    if (!arrayContentsEqual(writerManager, serviceData.writerManager ?? [])) {
      serviceUpdates.writerManager = writerManager;
    }

    if (pointOfContact === undefined) {
      pointOfContact = '';
    }

    if (pointOfContact !== serviceData.pointOfContact) {
      serviceUpdates.pointOfContact = pointOfContact.trim();
    }

    if (restrictedStatus && restrictedStatus !== serviceData.restrictedStatus) {
      serviceUpdates.restricted = restrictedStatus;
    }
    if (!arrayContentsEqual(pathSlugs, serviceData.pathslug ?? [])) {
      serviceUpdates.pathslug = pathSlugs;
    }

    if (docPackages && !deepEqual(serviceData.docPackages, docPackages)) {
      serviceUpdates.docPackages = docPackages;
    }
  }

  return serviceUpdates;
}

async function callPackageListApi(token: string) {
  const result = await callApi(
    new ListPackagesCommand({ pageSize: 2000 }),
    token
  );
  const packageNamesToFilter = ['AWSSolDoc-', 'AWSWPDoc-'];
  const availablePackages: string[] = result.packages
    .filter((pkg) => {
      return (
        (!pkg.serviceId || pkg.serviceId.startsWith('temp_id')) &&
        !packageNamesToFilter.some((substr) =>
          pkg.packageName.startsWith(substr)
        )
      );
    })
    .map((pkg) => pkg.packageName);
  return availablePackages;
}

async function callServiceEditApi(
  serviceId: string,
  serviceUpdates: ServiceUpdates,
  token: string
) {
  const updatesAsList = Object.entries(serviceUpdates).map(([key, value]) => {
    if (Array.isArray(value)) {
      return { [key]: JSON.stringify(value) };
    } else {
      return { [key]: String(value) };
    }
  });
  const requestBody = {
    serviceId: serviceId,
    updates: updatesAsList,
  };

  let apiResult: string;

  try {
    await callApi(new UpdateServiceCommand(requestBody), token);
    apiResult = '200';
  } catch (err) {
    console.log('error with FETCH', err);
    apiResult = 'An error was encountered while updating service details';
  }
  console.log('RESULT', apiResult);
  return apiResult;
}

export default EditService;
