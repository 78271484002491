import { ReactElement } from 'react';
import { Alert, Box, Button } from '@amzn/awsui-components-react/polaris';

export const ErrorRefreshPage = (): ReactElement => (
  <Box padding='xl'>
    <Alert
      visible={true}
      type='error'
      header={`No data retrieved`}
      action={
        <Button onClick={() => location.reload()}>Refresh browser </Button>
      }
    >
      An error was encountered. Try refreshing the page.
    </Alert>
  </Box>
);

export const ErrorGoToHomepage = (): ReactElement => (
  <Box padding='xl'>
    <Alert
      visible={true}
      type='error'
      header={`This page does not exist`}
      action={<Button href='#/'>Go to homepage</Button>}
    >
      An error was encountered. Go back to the Virtual Smiley home page or use
      the left navigation to browse.
    </Alert>
  </Box>
);

export const ErrorServiceNotFoundInRetrievedData = (): ReactElement => (
  <Box padding='xl'>
    <Alert
      visible={true}
      type='error'
      header={`The requested service does not exist`}
      action={<Button href='#/services'>Go to services list</Button>}
    >
      Go back to the services list and search for a valid package.
    </Alert>
  </Box>
);
