import { EntityIdentifier } from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import { ReactElement } from 'react';

export const EntityMap = ({
  entities,
}: {
  entities: EntityIdentifier[];
}): ReactElement => (
  <table>
    <thead>
      <tr>
        <th>Locale</th>
        <th>Entity ID</th>
      </tr>
    </thead>
    <tbody>
      {entities.map((id) => (
        <tr>
          <td>{id.locale}</td>
          <td>{id.entityId}</td>
        </tr>
      ))}
    </tbody>
  </table>
);
