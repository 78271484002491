import { EmptyStateProps } from '../../interfaces';
import { Box } from '@amzn/awsui-components-react';
import { ReactElement } from 'react';

export const EmptyState = ({
  title,
  subtitle,
  action,
}: EmptyStateProps): ReactElement => (
  <Box textAlign='center' color='inherit'>
    <Box variant='strong' textAlign='center' color='inherit'>
      {title}
    </Box>
    <Box variant='p' padding={{ bottom: 's' }} color='inherit'>
      {subtitle}
    </Box>
    {action}
  </Box>
);

export const EmptyStateNoButton = ({
  title,
  subtitle,
}: EmptyStateProps): ReactElement => (
  <Box textAlign='center' color='inherit'>
    <Box variant='strong' textAlign='center' color='inherit'>
      {title}
    </Box>
    <Box variant='p' padding={{ bottom: 's' }} color='inherit'>
      {subtitle}
    </Box>
  </Box>
);
