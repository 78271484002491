import { Dispatch, ReactElement, useEffect, useState } from 'react';
import debounce from 'debounce-promise';
import { Autosuggest, AutosuggestProps } from '@amzn/awsui-components-react';
import { AutoSuggestStatus } from '../types/AutoSuggestStatus';
import { nameAliasToOption } from '../types/NameAlias';
import { isDevDesk } from '../Pages/servicePackageInfo/utils';
import { useAuthState } from '../authentication';
import { callApi } from '../client';
import {
  GetNameAliasSuggestionsCommand,
  NameAlias,
} from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
type Props = {
  // Input label for accessibility
  ariaLabel?: string;

  // An alias to preload
  aliasToAdd?: string;

  // Callback function when an alias has been entered
  setAliasToAdd: Dispatch<string>;
};

const debouncedApi = debounce(
  async (
    hint: string,
    token: string,
    callback?: (status: AutoSuggestStatus) => void
  ) => {
    callback?.('loading');
    const res = await callApi(
      new GetNameAliasSuggestionsCommand({ hint }),
      token
    );
    if (!res.suggestions) throw 'No suggestions';
    callback?.('finished');
    return res.suggestions;
  },
  1000
);
export const AliasSuggester = ({
  ariaLabel,
  aliasToAdd,
  setAliasToAdd,
}: Props): ReactElement => {
  const { isAuthenticated, token } = useAuthState();
  const [availablePeopleStatus, setAvailablePeopleStatus] =
    useState<AutoSuggestStatus>('finished');
  const [availablePeopleList, setAvailablePeopleList] = useState<NameAlias[]>(
    []
  );
  const [availablePeopleOptions, setAvailablePeopleOptions] =
    useState<AutosuggestProps.Options>([]);

  const getAvailablePeople = async (): Promise<void> => {
    if (
      // Hint must be set and at least 3 characters
      !aliasToAdd ||
      aliasToAdd.length < 3
    )
      return setAvailablePeopleOptions([]);

    // Reuse last query result if current hint includes the last hint
    // if (aliasToAdd.includes(lastHint))
    //   return setAvailablePeopleOptions(
    //     availablePeopleList.map(nameAliasToOption)
    //   );
    if (
      // Fetch must not be disabled
      availablePeopleStatus === 'error' ||
      // Must be authenticated or running on a dev desktop
      !(isAuthenticated || isDevDesk())
    )
      return;
    try {
      if (!token) throw 'No token!';
      const availablePeople = await debouncedApi(
        aliasToAdd || '',
        token,
        setAvailablePeopleStatus
      );
      setAvailablePeopleList(availablePeople);
    } catch (err) {
      setAvailablePeopleStatus('error');
      console.log('error with FETCH', err);
    }
  };
  useEffect((): void => {
    if (availablePeopleList.length > 0) {
      const filteredPeopleOptions = availablePeopleList;
      const options: AutosuggestProps.Options =
        filteredPeopleOptions?.length > 0
          ? filteredPeopleOptions.sort().map(nameAliasToOption)
          : [];
      setAvailablePeopleOptions(options);
    }
  }, [availablePeopleList]);

  return (
    <Autosuggest
      onKeyUp={() => getAvailablePeople()}
      onChange={({ detail }) => setAliasToAdd(detail.value)}
      onBlur={() => setAvailablePeopleList([])}
      value={aliasToAdd ?? ''}
      options={availablePeopleOptions}
      enteredTextLabel={(value) => `Use: "${value}"`}
      ariaLabel={ariaLabel}
      placeholder='Search for a name or alias'
      empty='No matches found'
      loadingText='Loading people'
      statusType={availablePeopleStatus}
      errorText={'Error retrieving people.'}
      recoveryText={'Retry'}
      filteringType={'none'}
    />
  );
};
