import { Checkbox, FormField } from '@amzn/awsui-components-react';
import { ReactElement } from 'react';
import { FieldProps } from '../FieldProps';

export const LocGuidanceRequiredField = (
  props: FieldProps<boolean, false>
): ReactElement => (
  <FormField label='Localization guidance'>
    {props.old && props.old !== props.value && (
      <Checkbox disabled={true} checked={props.old}>
        Request guidance?
      </Checkbox>
    )}
    <Checkbox
      disabled={props.disabled}
      checked={props.value}
      onChange={(e) => props.onChange?.(e.detail.checked)}
    >
      Request guidance?
    </Checkbox>
  </FormField>
);
