import {
  VirtualSmileyClient,
  ServiceInputTypes,
  ServiceOutputTypes,
  $Command,
  VirtualSmileyClientResolvedConfig,
} from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import { getApiInvokeUrl } from './Pages/servicePackageInfo/utils';
import { MiddlewareStack } from '@smithy/types';
export const client = new VirtualSmileyClient({
  endpoint: getApiInvokeUrl(),
  region: 'us-east-1',
  credentials: {
    accessKeyId: 'elephant',
    secretAccessKey: 'stockings',
  },
});
export const addHeaderMiddleware = (
  client: {
    middlewareStack: MiddlewareStack<ServiceInputTypes, ServiceOutputTypes>;
  },
  token: string
): void =>
  client.middlewareStack.add(
    (next) => async (args) => {
      if (
        args.request &&
        typeof args.request === 'object' &&
        'headers' in args.request
      ) {
        Object.assign(
          (args.request as { headers: Record<string, string> }).headers,
          {
            'x-lambda-authorizer-token': token,
          }
        );
        // console.log('Added token', args.request.headers);
      }
      return next(args);
    },
    {
      step: 'build',
      name: 'headersMiddleware',
      tags: ['lambda-auth'],
    }
  );
type SecondTypeOf<T> = T extends $Command<infer _, infer R, infer __>
  ? R
  : never;
type CommandReturns<T> = Promise<SecondTypeOf<T>>;
export const callApi = <
  C extends $Command<any, any, VirtualSmileyClientResolvedConfig>
>(
  command: C,
  token: string
): CommandReturns<C> => {
  command.middlewareStack.removeByTag('lambda-auth');
  addHeaderMiddleware(command, token);
  return client.send(command) as CommandReturns<C>;
};
