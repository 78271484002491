import { LaunchStatus } from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import { FormField, Select } from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { ReactElement, useEffect, useState } from 'react';
import { FieldProps } from '../FieldProps';
const empty: OptionDefinition = {
  value: undefined,
  label: 'Select...',
};

const options = Object.entries(LaunchStatus).map(([value, label]) => ({
  value,
  label,
}));
export const LaunchStatusField = (
  props: FieldProps<LaunchStatus | undefined, true>
): ReactElement => {
  const [valid, setValid] = useState(false);
  useEffect(
    () => setValid(Boolean(props.value || !props.required)),
    [props.value, props.required]
  );
  useEffect(() => props.onValidate?.(valid), [valid]);
  return (
    <FormField label='Launch status'>
      {props.old && props.old !== props.value && (
        <Select
          disabled={true}
          options={[empty, ...options]}
          selectedOption={
            typeof props.old === 'string'
              ? options.find((o) => o.label === props.old) ?? empty
              : empty
          }
          placeholder='Empty'
        />
      )}
      <Select
        disabled={props.disabled}
        options={[empty, ...options]}
        selectedOption={
          typeof props.value === 'string'
            ? options.find((o) => o.label === props.value) ?? empty
            : empty
        }
        invalid={!props.disabled && !valid}
        placeholder='Select...'
        onChange={({
          detail: {
            selectedOption: { label },
          },
        }) => {
          props.onChange?.(
            label === 'Select...' ? undefined : (label as LaunchStatus)
          );
        }}
      />
    </FormField>
  );
};
