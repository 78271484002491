import {
  FormField,
  Input,
  Popover,
  TextContent,
} from '@amzn/awsui-components-react';
import { ReactElement, useEffect, useState } from 'react';
import { FieldProps } from '../FieldProps';

export const HomepageLinkField = (
  props: FieldProps<string, true>
): ReactElement => {
  const [valid, setValid] = useState(false);
  useEffect(() => {
    const isValid: boolean =
      (!props.required && props.disabled) ||
      (Boolean(props.value) && /^\/.{0,254}$/.test(props.value));

    setValid(isValid);
  }, [props.required, props.value, props.disabled]);
  useEffect(() => props.onValidate?.(valid), [valid]);
  return (
    <FormField
      label='Link to landing page or guide'
      errorText={
        !valid && props.value
          ? "Input must start with '/' and be between 1 and 255 characters."
          : undefined
      }
      description={
        <Popover
          triggerType='custom'
          position='right'
          size='small'
          content={
            <TextContent>
              <p>
                To link to a service landing page, enter the service pathslug.{' '}
                <br />
                <strong>Example:</strong> <code>/ec2/</code> <br />
                <br />
                To link directly to a guide, enter the path to the page. <br />
                <strong>Example:</strong>{' '}
                <code>
                  /one-enterprise/latest/userguide/what-is-one-enterprise.html
                </code>
              </p>
            </TextContent>
          }
        >
          Click for example usage
        </Popover>
      }
    >
      {props.old && props.old !== props.value && (
        <Input disabled={true} value={props.old} />
      )}
      <Input
        disabled={props.disabled}
        value={props.value}
        invalid={!valid}
        onChange={({ detail: { value } }) => {
          props.onChange?.(value);
        }}
      />
    </FormField>
  );
};
