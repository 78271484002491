import { FormField, Input } from '@amzn/awsui-components-react';
import { ReactElement, useEffect, useState } from 'react';
import { FieldProps } from '../FieldProps';

export const OfferingNameField = (
  props: FieldProps<string, true>
): ReactElement => {
  const [valid, setValid] = useState(false);
  useEffect(
    () => setValid(props.disabled || props.value.length > 0 || !props.required),
    [props]
  );
  useEffect(() => props.onValidate?.(valid), [valid]);
  return (
    <FormField label='Offering name'>
      {typeof props.old === 'string' && props.old !== props.value && (
        <Input disabled={true} value={props.old} />
      )}
      <Input
        disabled={props.disabled}
        value={props.value}
        invalid={!props.disabled && !valid}
        placeholder='e.g. Verified Access'
        onChange={(e) => {
          props.onChange?.(e.detail.value);
        }}
      />
    </FormField>
  );
};
