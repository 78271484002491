import { LocalizationWords } from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
import { FormField } from '@amzn/awsui-components-react';
import { ReactElement, useEffect, useState } from 'react';
import { Validatable } from '../../Validatable';
import { FieldProps } from '../FieldProps';

export const LocalizationWordsField = (
  props: FieldProps<LocalizationWords | undefined, true>
): ReactElement => {
  const [valid, setValid] = useState(true);
  useEffect(() => {
    setValid(Boolean(props.value?.words?.filter((w) => w.trim())?.length));
    console.log('length', props.value?.words);
  }, [props.value]);
  useEffect(() => props.onValidate?.(valid), [valid]);
  return (
    <FormField label='Localizable words (comma separated)'>
      <Validatable
        disabled={props.disabled}
        value={props.value?.words?.join(', ') ?? ''}
        invalid={!(props.disabled || valid)}
        placeholder={'e.g. Widget, service'}
        onChange={(e) => {
          props.onChange?.({
            localize: true,
            words: e.detail.value.split(/, ?/g),
          });
        }}
      />
    </FormField>
  );
};
