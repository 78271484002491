import {
  Offering,
  OfferingReview,
  RegionalNameInformation,
  RegionalNameInformationInText,
} from '@amzn/awsdev-docs-virtual-smiley-typescript-client';
export const delocalizeRegionMap = (
  localizableRegionMap: RegionalNameInformation
): RegionalNameInformationInText =>
  Object.entries(localizableRegionMap).reduce(
    (s, [i, item]) => ({
      ...s,
      [i]: Object.entries(item).reduce(
        (o, [k, v]) => ({
          ...o,
          [k]: (v as { displayName: string }).displayName, // TODO: anything but this
        }),
        {} // as RegionalNameInformationInText[keyof RegionalNameInformationInText]
      ),
    }),
    {} as RegionalNameInformationInText
  );
type PartialOfferingOrDraft = Partial<Offering | OfferingReview>;
type DelocalizeableKey =
  | 'extraLongName'
  | 'extraShortName'
  | 'longName'
  | 'shortName';
type DelocalizedOfferingOrDraft = {
  [K in keyof PartialOfferingOrDraft]: K extends DelocalizeableKey
    ? RegionalNameInformationInText
    : PartialOfferingOrDraft[K];
};

export const delocalizeAllRegionMaps = <O extends PartialOfferingOrDraft>(
  o: O
): DelocalizedOfferingOrDraft => ({
  ...o,
  extraLongName: o.extraLongName
    ? delocalizeRegionMap(o.extraLongName)
    : undefined,
  longName: o.longName ? delocalizeRegionMap(o.longName) : undefined,
  extraShortName: o.extraShortName
    ? delocalizeRegionMap(o.extraShortName)
    : undefined,
  shortName: o.shortName ? delocalizeRegionMap(o.shortName) : undefined,
});
