import styles from './LeadershipChainList.module.scss';
import { Link } from '@amzn/awsui-components-react';
import { ReactElement, ReactNode } from 'react';
import { Employee } from '@amzn/awsdev-docs-virtual-smiley-typescript-client';

type Props = { chain?: Employee[]; badges?: boolean };

const PhoneLink = ({
  alias,
  children,
}: {
  alias: string;
  children?: ReactNode;
}) => (
  <Link href={`https://phonetool.amazon.com/users/${alias}`}>
    {children ?? alias}
  </Link>
);
const phonetoolImageUrlForAlias = (alias: string) =>
  `https://internal-cdn.amazon.com/badgephotos.amazon.com/?fullsizeimage=1&give404ifmissing=1&uid=${alias}`;
export const Badge = ({ alias }: { alias: string }): ReactElement => (
  <div className={styles.Badge}>
    <img src={phonetoolImageUrlForAlias(alias)} />
    <p>{alias}</p>
  </div>
);
export const EmployeeListItem = ({
  level,
  firstName,
  lastName,
  alias,
}: Employee): ReactElement => (
  <li key={alias}>
    L{level}:&nbsp;
    <PhoneLink alias={alias}>
      {firstName && lastName && (
        <>
          {firstName}&nbsp;{lastName}&nbsp;({alias})
        </>
      )}
    </PhoneLink>
  </li>
);
export const LeadershipChainList = ({ chain }: Props): ReactElement =>
  chain?.length ? (
    <ul className={styles.LeadershipChainList}>
      {chain
        .filter(({ level }) => level > 6 && level < 12)
        .map(EmployeeListItem)}
    </ul>
  ) : (
    <>N/A</>
  );
