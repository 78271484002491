import styles from './Moniker.module.sass';
import { Route } from './Route';
import { ReactElement, useState } from 'react';
import { List } from './f2/forms/List';
import { CreateForm } from './f2/forms/CreateForm';
import { ViewReviewForm as ViewReviewForm } from './f2/forms/ViewReviewForm';
import { CreateEntitiesForm } from './f2/forms/CreateEntitiesForm';
export const Moniker = (): ReactElement => {
  const [hash, setHash] = useState(window.location.hash);
  window.addEventListener('hashchange', () => setHash(window.location.hash));
  if (/^#\/Moniker\/?$/.test(window.location.hash))
    window.location.hash = '/Moniker/offerings';
  return (
    <div className={styles.Moniker}>
      <div className={styles.routeList}>
        <Route
          match={/^#\/Moniker\/(drafts|offerings)\/?$/}
          hash={hash}
          from='left'
        >
          <List />
        </Route>
        <Route
          match={
            /^#\/?Moniker\/(create|(drafts|offerings)\/.+\/edit(-loc)?(\?context=.*)?)\/?$/
          }
          hash={hash}
        >
          <CreateForm />
        </Route>
        <Route
          match={/^#\/?Moniker\/(drafts|offerings)\/[^/]+\/?$/}
          hash={hash}
        >
          <ViewReviewForm />
        </Route>
        <Route
          match={/^#\/?Moniker\/offerings\/[^/]+\/create-entities\/?$/}
          hash={hash}
        >
          <CreateEntitiesForm />
        </Route>
      </div>
    </div>
  );
};

export default Moniker;
