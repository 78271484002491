import { CookieStorage } from 'amazon-cognito-auth-ts';
import {
  alphaEndpoint,
  betaEndpoint,
  gammaEndpoint,
  prodEndpoint,
  devEndpoint,
} from './endpoint';
import { CognitoAuthOptions } from 'amazon-cognito-auth-ts/lib/CognitoAuth';

// dev local host port. For dev endpoint callback and sign out URLS in Cognito "Moniker integration > Moniker client settings" page, change them to http://localhost:5000/
const devLocalPort = 8000;
//const for dev RedirectUriSignIn && Out
const localUrl = `http://localhost:${devLocalPort}`; //no trailing slash if no trailing slash in cognito fields

export const tokenScope: string[] = ['openid'];

const envEndpoints = {
  alpha: alphaEndpoint,
  beta: betaEndpoint,
  gamma: gammaEndpoint,
  prod: prodEndpoint,
  dev: devEndpoint,
} as const;

type Env = keyof typeof envEndpoints;

export const authConfig = (): CognitoAuthOptions => {
  const redirectUrl = getRedirectUrl();
  const env = getEnvironment();

  return {
    // The domain name in Cognito "Moniker integration > Domain name", without https://
    AppWebDomain: envEndpoints[env].appDomain,

    // the client ID from Cognito "General settings > Moniker clients" page
    ClientId: envEndpoints[env].devClientId,

    // exactly same as the callbacks URLS in Cognito "Moniker integration > Moniker client settings" page
    RedirectUriSignIn: redirectUrl,

    // exactly same as the sign out URLS in Cognito "Moniker integration > Moniker client settings" page
    RedirectUriSignOut: redirectUrl,

    TokenScopesArray: tokenScope,

    // the user pool from Cognito "General settings" page
    UserPoolId: envEndpoints[env].devEndpointUserPoolId,

    Storage: new CookieStorage(),
  };
};

const alphaHosts = {
  public: 'alpha.virtualsmiley.docs.aws.a2z.com',
  cloudFront: 'd21ibtn090ef7b.cloudfront.net',
};

const betaHosts = {
  public: 'beta.virtualsmiley.docs.aws.a2z.com',
  cloudFront: 'd3uww6o9l3cubu.cloudfront.net',
};

const gammaHosts = {
  public: 'gamma.virtualsmiley.docs.aws.a2z.com',
  cloudFront: 'd2prrdn98fxfj7.cloudfront.net',
};

const prodHosts = {
  public: 'prod.virtualsmiley.docs.aws.a2z.com',
  cloudFront: '',
};

const devHosts = {
  public: 'dev.virtualsmiley.docs.aws.a2z.com',
  cloudFront: 'd27io2mzrjlkdp.cloudfront.net',
};

const getRedirectUrl = () => {
  const { host } = window.location;
  const https = 'https://';

  if (host.includes('beta')) return https + betaHosts.public;

  for (const env of [
    alphaHosts.public,
    betaHosts.cloudFront,
    prodHosts.public,
    gammaHosts.public,
    devHosts.public,
  ])
    if (host.includes(env)) return https + env;

  return localUrl;
};

export const getEnvironment = (): Env => {
  const [subdomain] = window.location.host.split('.');
  return subdomain in envEndpoints ? (subdomain as Env) : 'beta';
};
